import React,{Component} from 'react';
import {Button} from "semantic-ui-react";
class ButtonRange extends Component{
    render() {
        return (
            <div>
                <div className="button-range">
                    <div className="track"></div>
                    {this.props.options.map((option,index)=>{
                        return(
                            this.props.selectedAnswer === option.value ?
                                <Button key={index}circular icon="checkmark" className="active" style={{left:option.left,color:"#FFFFFF",backgroundColor:option.color}}/>:
                                <Button key={index} circular icon="circle" onClick={(e)=>{
                                    e.preventDefault();
                                    this.props.handleSelect(option.value,option.score);
                                }} style={{left:option.left,color:"#A7A7A7"}}/>
                        )
                    })}
                    {this.props.options.map((option,index)=>{
                        return(
                            this.props.selectedAnswer === option.value?<span key={index} className="dot-text active-dot" style={{left:`calc( ${option.left})`,color:option.color}}>{option.text}</span>
                                :<span key={index} className="dot-text" style={{left:option.left}}>{option.text}</span>
                        )
                    })}
                </div>
            </div>
        );
    }
}
export default ButtonRange;