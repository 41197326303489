import React,{Component} from 'react';
import {Button, Label,Grid,GridColumn} from "semantic-ui-react";
class ButtonRangeMultipleWithNumber extends Component{
    render() {
        const rangeStyle={
            alignSelf:"center !important"
        };
        return(
            <div className="button-range-multiple">
                <Grid>
                    <GridColumn width={3}/>
                    <GridColumn width={13} style={{marginBottom:"20px"}}>
                        {this.props.incomeTaxOptions.map((option,index)=>{
                            return(
                                (index === 0 || index === 5 || index === 10)?<span key={index} style={{width:"200px",fontSize:"14px",position:"absolute",left: `calc( ${option.left} - 10%)`}}>{option.label}</span>:null
                            )
                        })}
                    </GridColumn>
                </Grid>
                <div className="button-range" style={{marginBottom:'6%'}}>
                    <Grid verticalAlign="top">
                        <GridColumn width={3}>
                            Income Tax
                        </GridColumn>
                        <GridColumn width={13}  style={rangeStyle}>
                            <div className="track"></div>
                            {this.props.incomeTaxOptions.map((option,index)=>{
                                return(
                                    this.props.selectedAnswer.incomeTax === option.value ?
                                        <Label key={index}circular className="active" style={{left:`calc( ${option.left} - 7px)`,color:"#FFFFFF",backgroundColor:option.color}}>{option.text}</Label>:
                                        <Button key={index} circular icon="circle" onClick={(e)=>{
                                            e.preventDefault();
                                            this.props.handleSelect("income_tax",option.value,option.score);
                                        }} style={{left:option.left,color:"#A7A7A7"}}/>
                                )
                            })}
                            {/*{this.props.incomeTaxOptions.map((option,index)=>{*/}
                                {/*return(*/}
                                    {/*this.props.selectedAnswer.incomeTax===option.value ?<span key={index} className="dot-text active-dot" style={{left:`calc( ${option.left} - 30px)`,color:option.color,width:"300px"}}>{option.label}</span>*/}
                                        {/*:null*/}
                                {/*)*/}
                            {/*})}*/}
                        </GridColumn>
                    </Grid>
                </div>
                <div className="button-range" style={{marginBottom:'6%'}}>
                    <Grid verticalAlign="top">
                        <GridColumn width={3}>
                            Capital Gains Tax
                        </GridColumn>
                        <GridColumn width={13}  style={rangeStyle}>
                            <div className="track"></div>
                            {this.props.capitalGainsTaxOptions.map((option,index)=>{
                                return(
                                    this.props.selectedAnswer.capitalTax === option.value ?
                                        <Label key={index}circular className="active" style={{left: `calc( ${option.left} - 7px)`,color:"#FFFFFF",backgroundColor:option.color}}>{option.text}</Label>:
                                        <Button key={index} circular icon="circle" onClick={(e)=>{
                                            e.preventDefault();
                                            this.props.handleSelect("capital_tax",option.value,option.score);
                                        }} style={{left:option.left,color:"#A7A7A7"}}/>
                                )
                            })}
                            {/*{this.props.capitalGainsTaxOptions.map((option,index)=>{*/}
                                {/*return(*/}
                                    {/*this.props.selectedAnswer.capitalTax===option.value ?<span key={index} className="dot-text active-dot" style={{left:`calc( ${option.left} - 30px)`,color:option.color,width:"300px"}}>{option.label}</span>*/}
                                        {/*:null*/}
                                {/*)*/}
                            {/*})}*/}
                        </GridColumn>
                    </Grid>
                </div>
                <div className="button-range" style={{marginBottom:'2%'}}>
                    <Grid verticalAlign="top">
                        <GridColumn width={3}>
                            Inheritance Tax
                        </GridColumn>
                        <GridColumn width={13}  className="range-center">
                            <div className="track"></div>
                            {this.props.inheritanceTaxOptions.map((option,index)=>{
                                return(
                                    this.props.selectedAnswer.inheritanceTax === option.value ?
                                        <Label key={index}circular className="active" style={{left: `calc( ${option.left} - 7px)`,color:"#FFFFFF",backgroundColor:option.color}}>{option.text}</Label>:
                                        <Button key={index} circular icon="circle" onClick={(e)=>{
                                            e.preventDefault();
                                            this.props.handleSelect("inheritance_tax",option.value,option.score);
                                        }} style={{left:option.left,color:"#A7A7A7"}}/>
                                )
                            })}
                            {/*{this.props.inheritanceTaxOptions.map((option,index)=>{*/}
                                {/*return(*/}
                                    {/*this.props.selectedAnswer.inheritanceTax===option.value ?<span key={index} className="dot-text active-dot" style={{left:`calc( ${option.left} - 30px)`,color:option.color,width:"300px"}}>{option.label}</span>*/}
                                        {/*:null*/}
                                {/*)*/}
                            {/*})}*/}
                        </GridColumn>
                    </Grid>
                </div>
                <div className="button-range" style={{marginBottom:'2%'}}>
                    <Grid verticalAlign="top">
                        <GridColumn width={3}>
                        National Insurance
                        </GridColumn>
                        <GridColumn width={13}  className="range-center">
                            <div className="track"></div>
                            {this.props.nationalTaxOptions.map((option,index)=>{
                                return(
                                    this.props.selectedAnswer.nationalTax === option.value ?
                                        <Label key={index}circular className="active" style={{left: `calc( ${option.left} - 7px)`,color:"#FFFFFF",backgroundColor:option.color}}>{option.text}</Label>:
                                        <Button key={index} circular icon="circle" onClick={(e)=>{
                                            e.preventDefault();
                                            this.props.handleSelect("national_tax",option.value,option.score);
                                        }} style={{left:option.left,color:"#A7A7A7"}}/>
                                )
                            })}
                       
                        </GridColumn>
                    </Grid>
                </div>
                <div className="button-range" style={{marginBottom:'6%'}}>
                    <Grid verticalAlign="top">
                        <GridColumn width={3}>
                            The need to complete a tax return
                        </GridColumn>
                        <GridColumn width={13} className="range-center">
                            <div className="track"></div>
                            {this.props.needToCompleteTaxOptions.map((option,index)=>{
                                return(
                                    this.props.selectedAnswer.needToCompleteTax === option.value ?
                                        <Label key={index}circular className="active" style={{left: `calc( ${option.left} - 7px)`,color:"#FFFFFF",backgroundColor:option.color}}>{option.text}</Label>:
                                        <Button key={index} circular icon="circle" onClick={(e)=>{
                                            e.preventDefault();
                                            this.props.handleSelect("need_to_complete_tax",option.value,option.score);
                                        }} style={{left:option.left,color:"#A7A7A7"}}/>
                                )
                            })}
                            {/*{this.props.needToCompleteTaxOptions.map((option,index)=>{*/}
                                {/*return(*/}
                                    {/*this.props.selectedAnswer.needToCompleteTax===option.value ?<span key={index} className="dot-text active-dot" style={{left:`calc( ${option.left} - 30px)`,color:option.color,width:"300px"}}>{option.label}</span>*/}
                                        {/*:null*/}
                                {/*)*/}
                            {/*})}*/}
                        </GridColumn>
                    </Grid>
                </div>
            </div>
        )
    }
}
export default ButtonRangeMultipleWithNumber;