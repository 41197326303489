import React, {Component} from 'react';
import {Dimmer, Header} from "semantic-ui-react";

class PageDimmer extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        const loadingDots = [".",".","..","...","....","....."];
        return (
            <Dimmer.Dimmable dimmer={this.props.showScore?"true":"false"}>
                <Dimmer active={this.props.showScore} style={{opacity: '0.90'}} page>
                    <Header className={this.props.sections?"dimmer-wrapper-section":"dimmer-wrapper-container"} textAlign="left"
                             content={this.props.content?<div><span>{this.props.content} </span><span>{this.props.timerCount?loadingDots[this.props.timerCount]:""}</span></div>:""}/>
                </Dimmer>
            </Dimmer.Dimmable>
        );
    }
}

export default PageDimmer;