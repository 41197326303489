import React, {Component} from 'react';
import {Image, Button, Icon,Header,Dimmer,Loader} from 'semantic-ui-react';
import * as landingLens from '../../../assets/logo-wrap-lens.png';
import * as logoWhite from '../../../assets/logo_white.png';
import {Link} from "react-router-dom";
import HowAppWorks from "../general/howAppWorks";
import axios from 'axios';
import * as routes from "../constants/api_routes";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInformation: false,
            loaded: 0,
            loader:false,
            success:null
        }
    }

    detectIE = () => {
        let ua = window.navigator.userAgent;

        let msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return true;
        }

        let trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            // let rv = ua.indexOf('rv:');
            return true;
        }

        let edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return false;
        }
        // other browser
        return false;
    };

    componentWillMount = async() =>{
        localStorage.clear();
        let pathName = this.props.location.pathname;
        if(pathName === '/'){
            this.setState({success:true})
        }
        else if(pathName.includes("wellbeing-")){
            this.setState({loader:true});
            let totalCode = pathName.split("/");
            totalCode = totalCode[1];
            if(totalCode.length > 2){
                this.setState({loader:false});
            }
            let formData = new FormData();
            formData.append("verification_code",totalCode);
            try {
                const response =  await axios.post(`${routes.VERIFY_ORGANISATION}`,formData);
                if(response.data[0] && response.data[0].id){
                    let organisation = {};
                    organisation.id = response.data[0].id;
                    organisation.sector_id = response.data[0].sector.id;
                    organisation.website = response.data[0].website;
                    organisation.contact = response.data[0].contact;
                    organisation.section_urls = response.data[0].section_urls;
                    organisation.is_whitelabel = response.data[0].is_whitelabel;
                    organisation.logo = response.data[0].image?`https://wellbeing.digiryte.com/api${response.data[0].image.url}`:"";
                    localStorage.setItem("organisation", JSON.stringify(organisation));
                    this.setState({
                        success:true,
                    });
                }
                else{
                    this.setState({ success:false});
                }
            }
            finally {
                this.setState({loader:false});
            }
        }
        else{
            this.props.history.push('/');
        }
    };


    render() {
        let organisation = JSON.parse(localStorage.getItem("organisation"));
        let orgLogo = organisation && organisation.logo && organisation.logo.length > 2 ? organisation.logo:null;
        const isIE = this.detectIE();
        return (
            <div>
                {isIE && <div className="ie-notice">
                        <Header content="It appears you're using a browser that we don't fully support" textAlign="center"/>
                        <div className="ie-notice-content">
                            Some features might not be working as expected. We recommend updating your browser or try using another browser such as Google Chrome, Firefox or Safari.
                        </div>
                </div>}
                <Dimmer active={this.state.loader}>
                    <Loader>Loading...</Loader>
                </Dimmer>
                <div className="home-main-div">
                    <div className="logo-image">
                        <Image src={landingLens} onLoad={() => this.setState({loaded: this.state.loaded + 1})}
                               className="outline-lens-logo"/>
                        <Image src={orgLogo||logoWhite} onLoad={() => this.setState({loaded: this.state.loaded + 1})}
                               className="logo-white"/>
                    </div>
                    {this.state.loaded >= 2 && <div>
                        <div className="home-content">
                            <div className="home-title">
                                How financially fit are you?
                            </div>
                            {/* <div className="home-description">Financial Wellbeing Index</div> */}
                        </div>
                        <div className="start-button">
                            {this.state.success === true && <Button style={{fontSize: 20}} as={Link} to="/info" secondary>Take the test</Button>}
                            {this.state.success === false && <div style={{ color: "#ff0707"}}>
                                Your verification code is not valid. Please contact your organisation!
                            </div>}
                        </div>
                    </div>}
                </div>
                <div className="our-methodology" onClick={() => this.setState({showInformation: true})}><Icon name="cogs" size="large"/> How the test works
                </div>
                {this.state.showInformation && <HowAppWorks organisation={organisation?organisation:null} close={() => this.setState({showInformation: false})}/>}
            </div>
        )
    }
}

export default Home;