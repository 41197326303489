/*Tax section questions*/
import React from "react";
export const taxQuestions = [
  {
    id: 17,
    question:
      "Do you think you take advantage of all relevant tax allowances and reliefs available to you?",
    options: [
      {
        key: 117,
        text: (
          <span>
            Yes - I’m aware of the tax allowances and relief available to me and{" "}
            <b>take full advantage</b> of them
          </span>
        ),
        value: 117,
        question_id: 17,
        score: 10,
      },
      {
        key: 118,
        text: (
          <span>
            I’m aware of the tax allowances and relief available to me but{" "}
            <b>do not take full advantage</b> of them{" "}
          </span>
        ),
        value: 118,
        question_id: 17,
        score: 7,
      },
      {
        key: 119,
        text: (
          <span>
            I’m aware of the tax allowances and relief available to me but{" "}
            <b>I’m not sure whether I use it all</b>
          </span>
        ),
        value: 119,
        question_id: 17,
        score: 3,
      },
      {
        key: 120,
        text: (
          <span>
            I don’t know what tax allowances and{" "}
            <b>relief are available to me</b>
          </span>
        ),
        value: 120,
        question_id: 17,
        score: 0,
      },
    ],
  },
  {
    id: 18,
    question: "How well do you understand the following?",
    incomeTaxoptions: [
      {
        key: 121,
        text: "0",
        value: 121,
        question_id: 18,
        score: 0,
        left: "0%",
        color: "#DA291C",
        label: "I don’t understand it at all",
      },
      {
        key: 122,
        text: "1",
        value: 122,
        question_id: 18,
        score: 1,
        left: "10%",
        color: "#FA8765",
        label: "I don’t understand it at all",
      },
      {
        key: 123,
        text: "2",
        value: 123,
        question_id: 18,
        score: 2,
        left: "20%",
        color: "#FA8765",
        label: "I don’t understand it at all",
      },
      {
        key: 124,
        text: "3",
        value: 124,
        question_id: 18,
        score: 3,
        left: "30%",
        color: "#FF8200",
        label: "I don’t understand it at all",
      },
      {
        key: 125,
        text: "4",
        value: 125,
        question_id: 18,
        score: 4,
        left: "40%",
        color: "#FF8200",
        label: "I don’t understand it at all",
      },
      {
        key: 126,
        text: "5",
        value: 126,
        question_id: 18,
        score: 5,
        left: "50%",
        color: "#FFCD00",
        label: "I understand it somewhat",
      },
      {
        key: 127,
        text: "6",
        value: 127,
        question_id: 18,
        score: 6,
        left: "60%",
        color: "#C7D14C",
        label: "I completely understand it",
      },
      {
        key: 128,
        text: "7",
        value: 128,
        question_id: 18,
        score: 7,
        left: "70%",
        color: "#C7D14C",
        label: "I completely understand it",
      },
      {
        key: 129,
        text: "8",
        value: 129,
        question_id: 18,
        score: 8,
        left: "80%",
        color: "#A3E056",
        label: "I completely understand it",
      },
      {
        key: 130,
        text: "9",
        value: 130,
        question_id: 18,
        score: 9,
        left: "90%",
        color: "#A3E056",
        label: "I completely understand it",
      },
      {
        key: 131,
        text: "10",
        value: 131,
        question_id: 18,
        score: 10,
        left: "100%",
        color: "#78BE20",
        label: "I completely understand it",
      },
    ],
    capitalGainsTaxOptions: [
      {
        key: 132,
        text: "0",
        value: 132,
        question_id: 18,
        score: 0,
        left: "0%",
        color: "#DA291C",
        label: "I don’t understand it at all",
      },
      {
        key: 133,
        text: "1",
        value: 133,
        question_id: 18,
        score: 1,
        left: "10%",
        color: "#FA8765",
        label: "I don’t understand it at all",
      },
      {
        key: 134,
        text: "2",
        value: 134,
        question_id: 18,
        score: 2,
        left: "20%",
        color: "#FA8765",
        label: "I don’t understand it at all",
      },
      {
        key: 135,
        text: "3",
        value: 135,
        question_id: 18,
        score: 3,
        left: "30%",
        color: "#FF8200",
        label: "I don’t understand it at all",
      },
      {
        key: 136,
        text: "4",
        value: 136,
        question_id: 18,
        score: 4,
        left: "40%",
        color: "#FF8200",
        label: "I don’t understand it at all",
      },
      {
        key: 137,
        text: "5",
        value: 137,
        question_id: 18,
        score: 5,
        left: "50%",
        color: "#FFCD00",
        label: "I understand it somewhat",
      },
      {
        key: 138,
        text: "6",
        value: 138,
        question_id: 18,
        score: 6,
        left: "60%",
        color: "#C7D14C",
        label: "I completely understand it",
      },
      {
        key: 139,
        text: "7",
        value: 139,
        question_id: 18,
        score: 7,
        left: "70%",
        color: "#C7D14C",
        label: "I completely understand it",
      },
      {
        key: 140,
        text: "8",
        value: 140,
        question_id: 18,
        score: 8,
        left: "80%",
        color: "#A3E056",
        label: "I completely understand it",
      },
      {
        key: 141,
        text: "9",
        value: 141,
        question_id: 18,
        score: 9,
        left: "90%",
        color: "#A3E056",
        label: "I completely understand it",
      },
      {
        key: 142,
        text: "10",
        value: 142,
        question_id: 18,
        score: 10,
        left: "100%",
        color: "#78BE20",
        label: "I completely understand it",
      },
    ],
    inheritanceTaxOptions: [
      {
        key: 143,
        text: "0",
        value: 143,
        question_id: 18,
        score: 0,
        left: "0%",
        color: "#DA291C",
        label: "I don’t understand it at all",
      },
      {
        key: 144,
        text: "1",
        value: 144,
        question_id: 18,
        score: 1,
        left: "10%",
        color: "#FA8765",
        label: "I don’t understand it at all",
      },
      {
        key: 145,
        text: "2",
        value: 145,
        question_id: 18,
        score: 2,
        left: "20%",
        color: "#FA8765",
        label: "I don’t understand it at all",
      },
      {
        key: 146,
        text: "3",
        value: 146,
        question_id: 18,
        score: 3,
        left: "30%",
        color: "#FF8200",
        label: "I don’t understand it at all",
      },
      {
        key: 147,
        text: "4",
        value: 147,
        question_id: 18,
        score: 4,
        left: "40%",
        color: "#FF8200",
        label: "I don’t understand it at all",
      },
      {
        key: 148,
        text: "5",
        value: 148,
        question_id: 18,
        score: 5,
        left: "50%",
        color: "#FFCD00",
        label: "I understand it somewhat",
      },
      {
        key: 149,
        text: "6",
        value: 149,
        question_id: 18,
        score: 6,
        left: "60%",
        color: "#C7D14C",
        label: "I completely understand it",
      },
      {
        key: 150,
        text: "7",
        value: 150,
        question_id: 18,
        score: 7,
        left: "70%",
        color: "#C7D14C",
        label: "I completely understand it",
      },
      {
        key: 151,
        text: "8",
        value: 151,
        question_id: 18,
        score: 8,
        left: "80%",
        color: "#A3E056",
        label: "I completely understand it",
      },
      {
        key: 152,
        text: "9",
        value: 152,
        question_id: 18,
        score: 9,
        left: "90%",
        color: "#A3E056",
        label: "I completely understand it",
      },
      {
        key: 153,
        text: "10",
        value: 153,
        question_id: 18,
        score: 10,
        left: "100%",
        color: "#78BE20",
        label: "I completely understand it",
      },
    ],
    nationalTaxOptions: [
      {
        key: 176,
        text: "0",
        value: 176,
        question_id: 18,
        score: 0,
        left: "0%",
        color: "#DA291C",
        label: "I don’t understand it at all",
      },
      {
        key: 177,
        text: "1",
        value: 177,
        question_id: 18,
        score: 1,
        left: "10%",
        color: "#FA8765",
        label: "I don’t understand it at all",
      },
      {
        key: 178,
        text: "2",
        value: 178,
        question_id: 18,
        score: 2,
        left: "20%",
        color: "#FA8765",
        label: "I don’t understand it at all",
      },
      {
        key: 179,
        text: "3",
        value: 179,
        question_id: 18,
        score: 3,
        left: "30%",
        color: "#FF8200",
        label: "I don’t understand it at all",
      },
      {
        key: 180,
        text: "4",
        value: 180,
        question_id: 18,
        score: 4,
        left: "40%",
        color: "#FF8200",
        label: "I don’t understand it at all",
      },
      {
        key: 181,
        text: "5",
        value: 181,
        question_id: 18,
        score: 5,
        left: "50%",
        color: "#FFCD00",
        label: "I understand it somewhat",
      },
      {
        key: 182,
        text: "6",
        value: 182,
        question_id: 18,
        score: 6,
        left: "60%",
        color: "#C7D14C",
        label: "I completely understand it",
      },
      {
        key: 183,
        text: "7",
        value: 183,
        question_id: 18,
        score: 7,
        left: "70%",
        color: "#C7D14C",
        label: "I completely understand it",
      },
      {
        key: 184,
        text: "8",
        value: 184,
        question_id: 18,
        score: 8,
        left: "80%",
        color: "#A3E056",
        label: "I completely understand it",
      },
      {
        key: 185,
        text: "9",
        value: 185,
        question_id: 18,
        score: 9,
        left: "90%",
        color: "#A3E056",
        label: "I completely understand it",
      },
      {
        key: 186,
        text: "10",
        value: 186,
        question_id: 18,
        score: 10,
        left: "100%",
        color: "#78BE20",
        label: "I completely understand it",
      },
    ],
    needToCompleteTaxOptions: [
      {
        key: 154,
        text: "0",
        value: 154,
        question_id: 18,
        score: 0,
        left: "0%",
        color: "#DA291C",
        label: "I don’t understand it at all",
      },
      {
        key: 155,
        text: "1",
        value: 155,
        question_id: 18,
        score: 1,
        left: "10%",
        color: "#FA8765",
        label: "I don’t understand it at all",
      },
      {
        key: 156,
        text: "2",
        value: 156,
        question_id: 18,
        score: 2,
        left: "20%",
        color: "#FA8765",
        label: "I don’t understand it at all",
      },
      {
        key: 157,
        text: "3",
        value: 157,
        question_id: 18,
        score: 3,
        left: "30%",
        color: "#FF8200",
        label: "I don’t understand it at all",
      },
      {
        key: 158,
        text: "4",
        value: 158,
        question_id: 18,
        score: 4,
        left: "40%",
        color: "#FF8200",
        label: "I don’t understand it at all",
      },
      {
        key: 159,
        text: "5",
        value: 159,
        question_id: 18,
        score: 5,
        left: "50%",
        color: "#FFCD00",
        label: "I understand it somewhat",
      },
      {
        key: 160,
        text: "6",
        value: 160,
        question_id: 18,
        score: 6,
        left: "60%",
        color: "#C7D14C",
        label: "I completely understand it",
      },
      {
        key: 161,
        text: "7",
        value: 161,
        question_id: 18,
        score: 7,
        left: "70%",
        color: "#C7D14C",
        label: "I completely understand it",
      },
      {
        key: 162,
        text: "8",
        value: 162,
        question_id: 18,
        score: 8,
        left: "80%",
        color: "#A3E056",
        label: "I completely understand it",
      },
      {
        key: 163,
        text: "9",
        value: 163,
        question_id: 18,
        score: 9,
        left: "90%",
        color: "#A3E056",
        label: "I completely understand it",
      },
      {
        key: 164,
        text: "10",
        value: 164,
        question_id: 18,
        score: 10,
        left: "100%",
        color: "#78BE20",
        label: "I completely understand it",
      },
    ],
  },
  {
    id: 19,
    question: "How up to date are your tax records and reporting?",
    options: [
      {
        key: 165,
        text: "Completely up to date",
        value: 165,
        question_id: 19,
        score: 10,
        left: "0%",
        color: "#DA291C",
      },
      {
        key: 166,
        text: "Somewhat up to date",
        value: 166,
        question_id: 19,
        score: 5,
        left: "25%",
        color: "#FF8200",
      },
      {
        key: 167,
        text: "My records are incomplete/ not up to date",
        value: 167,
        question_id: 19,
        score: -3,
        left: "50%",
        color: "#FFCD00",
      },
      {
        key: 168,
        text: "I don’t know",
        value: 168,
        question_id: 19,
        score: -5,
        left: "75%",
        color: "#A8DD66",
      },
      {
        key: 169,
        text: "NA - I am not required to keep tax records or report",
        value: 169,
        question_id: 19,
        score: 10,
        left: "100%",
        color: "#78BE20",
      },
    ],
  },
];
