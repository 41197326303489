import React, { Component } from "react";
import {
  Button,
  Grid,
  GridColumn,
  Icon,
  Image,
  Popup,
} from "semantic-ui-react";
import * as logo from "../../../assets/logo_white.png";
import SectionList from "../general/sectionList";
import ScoreCard from "../general/scoreCard";
import ButtonRange from "../general/buttonRange";
import { propertiesAndMortgagesQuestions } from "../../../utils/propertiesAndMortgagesQuestions";
import PageDimmer from "../general/pageDimmer";
import RangeSliderWithRadio from "../general/rangeSliderWithRadio";
import {
  scrollSmoothToBottom,
  scrollSmoothToTop,
} from "../../../utils/scrollSmooth";

class Section6 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeQuestion: 1,
      scrollPosition: 1,
      selectedAnswerForQuestion1: null,
      scoreForQuestion1: 0,
      selectedAnswerForQuestion2: null,
      scoreForQuestion2: 0,
      disableBlur: false,
      allQuestionsAnswered: false,
      timerCount: 0,
      question1Answered: 0,
      question2Answered: 0,
      question3Answered: 0,
      selectedLabelForQuestion1: null,
      selectedSliderValueForQuestion1: null,
      selectedAnswerForQuestion3: null,
      scoreForQuestion3: 0,
    };
    this.question1ref = React.createRef();
    this.question2ref = React.createRef();
    this.question3ref = React.createRef();
    let totalScore = 0;
    let valueSet = null;
  }

  componentWillMount() {
    if (this.props.sectionAnswers) {
      const [
        selectedAnswerForQuestion1,
        selectedAnswerForQuestion2,
        selectedAnswerForQuestion3,
      ] = this.props.sectionAnswers;
      const [scoreForQuestion1, scoreForQuestion2, scoreForQuestion3] =
        this.props.sectionScores;
      const { selectedLabelForQuestion1, selectedSliderValueForQuestion1 } =
        this.props.additional;
      this.setState({
        selectedAnswerForQuestion1,
        selectedAnswerForQuestion2,
        selectedAnswerForQuestion3,
        scoreForQuestion1,
        scoreForQuestion2,
        scoreForQuestion3,
        selectedLabelForQuestion1,
        selectedSliderValueForQuestion1,
        disableBlur: true,
        activeQuestion: 2,
        update: true,
        allQuestionsAnswered: true,
      });
    }
  }

  handleSelectAnswerForQuestion1 = (value, source) => {
    let answer = this.state.selectedAnswerForQuestion1;
    let label = this.state.selectedLabelForQuestion1;
    let score = this.state.scoreForQuestion1;
    if (value >= 0 && value <= 10) {
      label = "1-15%";
    }
    if (value >= 11 && value <= 20) {
      label = "16-30%";
    }
    if (value >= 21 && value <= 30) {
      label = "31-45%";
    }
    if (value >= 31 && value <= 40) {
      label = "46-50%";
    }
    if (value >= 41 && value <= 50) {
      label = "51-60%";
    }
    if (value >= 51 && value <= 60) {
      label = "61-70%";
    }
    if (value >= 61 && value <= 70) {
      label = "71-100%";
    }
    if (value >= 71 && value <= 80) {
      label = ">100%";
    }
    if (source === "radio") {
      label = value;
    }
    let sliderOptions = propertiesAndMortgagesQuestions[0].sliderOptions;
    for (let i in sliderOptions) {
      if (sliderOptions[i].text === label) {
        answer = sliderOptions[i].value;
        score = sliderOptions[i].score;
      }
    }
    if (source === "radio") {
      value = 0;
    }
    this.setState({
      selectedLabelForQuestion1: label,
      selectedAnswerForQuestion1: answer,
      selectedSliderValueForQuestion1: value,
      scoreForQuestion1: score,
      question1Answered: 1,
    });
    if (this.valueSet) {
      clearTimeout(this.valueSet);
    }
    this.valueSet = setTimeout(() => {
      this.setState({ activeQuestion: 2, scrollPosition: 2 });
      scrollSmoothToTop(
        this.question2ref.current.offsetTop,
        this.question1ref.current
      );
    }, 1500);
  };

  handleSelectAnswerForQuestion2 = (option, score) => {
    this.setState({
      selectedAnswerForQuestion2: option,
      scoreForQuestion2: score,
      question2Answered: 1,
      disableBlur: true,
    });
    if (this.valueSet) {
      clearTimeout(this.valueSet);
    }
    this.valueSet = setTimeout(() => {
      this.setState({ activeQuestion: 3, scrollPosition: 3 });
      scrollSmoothToTop(
        this.question3ref.current.offsetTop,
        this.question2ref.current
      );
    }, 1500);
  };
  handleSelectAnswerForQuestion3 = (option, score) => {
    this.setState({
      selectedAnswerForQuestion3: option,
      scoreForQuestion3: score,
      allQuestionsAnswered: true,
      question3Answered: 1,
      disableBlur: true,
    });
  };

  handleSelectedScroll = (q) => {
    const currentScrollPosition = this.state.scrollPosition;
    if (q === 1) {
      if (currentScrollPosition > q) {
        scrollSmoothToBottom(
          this.question2ref.current.offsetTop,
          this.question1ref.current
        );
      }
      this.setState({ scrollPosition: 1 });
    }
    if (q === 2) {
      if (currentScrollPosition > q) {
        scrollSmoothToBottom(
          this.question2ref.current.offsetTop -
            this.question1ref.current.offsetTop,
          this.question2ref.current
        );
      } else {
        scrollSmoothToTop(
          this.question2ref.current.offsetTop,
          this.question1ref.current
        );
      }
      this.setState({ scrollPosition: 2 });
    }
    if (q === 3) {
        if (currentScrollPosition > q) {
          scrollSmoothToBottom(
            this.question3ref.current.offsetTop -
              this.question2ref.current.offsetTop,
            this.question3ref.current
          );
        } else {
          scrollSmoothToTop(
            this.question3ref.current.offsetTop,
            this.question2ref.current
          );
        }
        this.setState({ scrollPosition: 3 });
      }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let answerIds = [];
    let scores = [];
    answerIds.push(this.state.selectedAnswerForQuestion1);
    answerIds.push(this.state.selectedAnswerForQuestion2);
    answerIds.push(this.state.selectedAnswerForQuestion3);
    scores.push(this.state.scoreForQuestion1);
    scores.push(this.state.scoreForQuestion2);
    scores.push(this.state.scoreForQuestion3);
    let additional = {
      selectedLabelForQuestion1: this.state.selectedLabelForQuestion1,
      selectedSliderValueForQuestion1:
        this.state.selectedSliderValueForQuestion1,
    };
    this.totalScore =
      ((this.state.scoreForQuestion1 + this.state.scoreForQuestion2 + this.state.scoreForQuestion3) / 30) *
      100;
    this.setState({ showScore: true });
    let countdown = setInterval(() => {
      this.setState({ timerCount: this.state.timerCount + 1 });
      if (this.state.timerCount > 3) {
        clearInterval(countdown);
        this.props.handleSubmit(answerIds, this.totalScore, scores, additional);
      }
    }, 1000);
  };

  render() {
    const { activeQuestion, scrollPosition } = this.state;
    const { orgLogo } = this.props;

    return (
      <div>
        <PageDimmer
          showScore={this.state.showScore}
          timerCount={this.state.timerCount}
          content={"Loading next section"}
          sections={true}
        />
        <Grid className="section-main-div">
          <GridColumn width={3} className="section-details">
            <Image src={orgLogo || logo} />
            <SectionList activeSection={6} />
            <ScoreCard
              sectionName="Property & mortgages"
              score={this.totalScore}
              showScore={this.state.showScore}
            />
          </GridColumn>
          <GridColumn width={13} className="lens-div">
            <div className="lens">
              <Image
                src="/web_lens_full.png"
                onLoad={() => {
                  this.setState({ loader: false });
                }}
              />
            </div>
            <div className="nav-indicator">
              <span
                className={
                  scrollPosition === 1
                    ? "dot active-6 section-6"
                    : "dotNav section-6"
                }
                onClick={() =>
                  activeQuestion >= 1 ? this.handleSelectedScroll(1) : null
                }
              ></span>
              <span
                className={
                  scrollPosition === 2
                    ? "dot active-6 section-6"
                    : "dotNav section-6"
                }
                onClick={() =>
                  activeQuestion >= 2 ? this.handleSelectedScroll(2) : null
                }
              ></span>
              <span
                className={
                  scrollPosition === 3
                    ? "dot active-6 section-6"
                    : "dotNav section-6"
                }
                onClick={() =>
                  activeQuestion >= 3 ? this.handleSelectedScroll(3) : null
                }
              ></span>
            </div>
            <div
              className="questions-list"
              style={
                this.state.selectedAnswerForQuestion1
                  ? { height: "25em", top: "30%" }
                  : { height: "25em", top: "25%" }
              }
            >
              <div
                className={
                  "question-item " +
                  (activeQuestion >= 1 || this.state.disableBlur ? "" : "blur")
                }
                ref={this.question1ref}
              >
                <div className="question">
                  <span>
                    <span>1.</span>
                    <span>{propertiesAndMortgagesQuestions[0].question}</span>
                    <Popup
                      trigger={
                        <Icon circular name="info" className="info-popup" />
                      }
                      content="Housing costs include the cost of rent, mortgage repayments, rates, building and home insurance, maintenance costs and so on."
                      size="small"
                    />
                  </span>
                </div>
                <div className="answer">
                  <RangeSliderWithRadio
                    min={0}
                    max={80}
                    step={10}
                    label=""
                    sliderValue={this.state.selectedSliderValueForQuestion1}
                    selectedLabel={this.state.selectedLabelForQuestion1}
                    selectedAnswer={this.state.selectedAnswerForQuestion1}
                    handleSelect={this.handleSelectAnswerForQuestion1}
                  />
                </div>
              </div>
              <div
                className={
                  "question-item " +
                  (activeQuestion >= 2 || this.state.disableBlur ? "" : "blur")
                }
                ref={this.question2ref}
              >
                <div className="question">
                  <span>2.</span>
                  <span>{propertiesAndMortgagesQuestions[1].question}</span>
                </div>
                <div className="answer">
                  <ButtonRange
                    options={propertiesAndMortgagesQuestions[1].options}
                    selectedAnswer={this.state.selectedAnswerForQuestion2}
                    handleSelect={this.handleSelectAnswerForQuestion2}
                  />
                </div>
              </div>
              <div
                className={
                  "question-item " +
                  (activeQuestion >= 3 || this.state.disableBlur ? "" : "blur")
                }
                ref={this.question3ref}
              >
                <div className="question">
                  <span>3.</span>
                  <span>{propertiesAndMortgagesQuestions[2].question}</span>
                </div>
                <div className="answer">
                  <ButtonRange
                    options={propertiesAndMortgagesQuestions[2].options}
                    selectedAnswer={this.state.selectedAnswerForQuestion3}
                    handleSelect={this.handleSelectAnswerForQuestion3}
                  />
                </div>
              </div>
              {this.state.allQuestionsAnswered && !this.state.showScore && (
                <div className="next-button">
                  <Button onClick={this.handleSubmit}>
                    {this.state.update ? "Update" : "Next"}
                  </Button>
                </div>
              )}
              <div style={{ height: "180px" }}></div>
            </div>
          </GridColumn>
        </Grid>
      </div>
    );
  }
}
export default Section6;
