import React,{Component} from 'react';
import {Route,Switch} from 'react-router-dom';
import * as routes from "../constants/appRoutes";
import Home from "../home";
import AboutYou from "../about";
import SurveyList from "../index";
class AppRoutes extends Component{
    render(){
        return(
            <div>
                <Switch>
                    <Route exact path = {routes.HOME} component={Home}/>
                    <Route exact path = {routes.ABOUT} component={AboutYou}/>
                    <Route exact path = {routes.SURVEY_SECTIONS} component={SurveyList}/>
                    <Route component={Home} />
                </Switch>
            </div>
        );
    }
}
export default AppRoutes;