/*Savings and investments section questions*/
export const savingsAndInvestmentsQuestions = [
    {
        id:11,
        question:"Overall how confident are you that you will be able to achieve your  savings goals?",
        shortTermGoalOptions:[
            {key: 56, text: "Very unconfident", value:56, question_id: 11, score: 0,left:"0%",color:"#DA291C"},
            {key: 57, text: "Somewhat unconfident", value:57, question_id: 11, score: 2.5,left:"25%",color:"#FF8200"},
            {key: 58, text: "Neither confident nor unconfident", value:58, question_id:11, score: 5,left:"50%",color:"#FFCD00"},
            {key: 59, text: "Somewhat confident", value:59, question_id: 11, score: 7.5,left:"75%",color:"#A8DD66"},
            {key: 60, text: "Very confident", value:60, question_id: 11, score: 10,left:"100%",color:"#78BE20"},
        ],
        shortTermNa:{value:61,score:-2.5},
        mediumTermGoalOptions:[
            {key: 62, text: "Very unconfident", value:62, question_id: 11, score: 0,left:"0%",color:"#DA291C"},
            {key: 63, text: "Somewhat unconfident", value:63, question_id: 11, score: 2.5,left:"25%",color:"#FF8200"},
            {key: 64, text: "Neither confident nor unconfident", value:64, question_id:11, score: 5,left:"50%",color:"#FFCD00"},
            {key: 65, text: "Somewhat confident", value:65, question_id: 11, score: 7.5,left:"75%",color:"#A8DD66"},
            {key: 66, text: "Very confident", value:66, question_id: 11, score: 10,left:"100%",color:"#78BE20"},
        ],
        mediumTermNa:{value:67,score:-2.5},
        longTermGoalOptions:[
            {key: 68, text: "Very unconfident", value:68, question_id: 11, score: 0,left:"0%",color:"#DA291C"},
            {key: 69, text: "Somewhat unconfident", value:69, question_id: 11, score: 2.5,left:"25%",color:"#FF8200"},
            {key: 70, text: "Neither confident nor unconfident", value:70, question_id:11, score: 5,left:"50%",color:"#FFCD00"},
            {key: 71, text: "Somewhat confident", value:71, question_id: 11, score: 7.5,left:"75%",color:"#A8DD66"},
            {key: 72, text: "Very confident", value:72, question_id: 11, score: 10,left:"100%",color:"#78BE20"},
        ],
        longTermNa:{value:73,score:-2.5}
    },
    {
        id:12,
        question:"How confident are you in choosing the most suitable financial product(s) for the following savings goals?",
        shortTermGoalOptions:[
            {key: 74, text: "Very unconfident", value:74, question_id: 11, score: 0,left:"0%",color:"#DA291C"},
            {key: 75, text: "Somewhat unconfident", value:75, question_id: 11, score: 2.5,left:"25%",color:"#FF8200"},
            {key: 76, text: "Neither confident nor unconfident", value:76, question_id:11, score: 5,left:"50%",color:"#FFCD00"},
            {key: 77, text: "Somewhat confident", value:77, question_id: 11, score: 7.5,left:"75%",color:"#A8DD66"},
            {key: 78, text: "Very confident", value:78, question_id: 11, score: 10,left:"100%",color:"#78BE20"},
        ],
        shortTermNa:{value:79,score:-2.5},
        mediumTermGoalOptions:[
            {key: 80, text: "Very unconfident", value:80, question_id: 11, score: 0,left:"0%",color:"#DA291C"},
            {key: 81, text: "Somewhat unconfident", value:81, question_id: 11, score: 2.5,left:"25%",color:"#FF8200"},
            {key: 82, text: "Neither confident nor unconfident", value:82, question_id:11, score: 5,left:"50%",color:"#FFCD00"},
            {key: 83, text: "Somewhat confident", value:83, question_id: 11, score: 7.5,left:"75%",color:"#A8DD66"},
            {key: 84, text: "Very confident", value:84, question_id: 11, score: 10,left:"100%",color:"#78BE20"},
        ],
        mediumTermNa:{value:85,score:-2.5},
        longTermGoalOptions:[
            {key: 86, text: "Very unconfident", value:86, question_id: 11, score: 0,left:"0%",color:"#DA291C"},
            {key: 87, text: "Somewhat unconfident", value:87, question_id: 11, score: 2.5,left:"25%",color:"#FF8200"},
            {key: 88, text: "Neither confident nor unconfident", value:88, question_id:11, score: 5,left:"50%",color:"#FFCD00"},
            {key: 89, text: "Somewhat confident", value:89, question_id: 11, score: 7.5,left:"75%",color:"#A8DD66"},
            {key: 90, text: "Very confident", value:90, question_id: 11, score: 10,left:"100%",color:"#78BE20"},
        ],
        longTermNa:{value:91,score:-2.5}
    },
]