/*Retirement section questions*/
export const retirementQuestions = [
    {
        id:15,
        question:"How confident are you that you will be able to afford to retire at the age you want?",
        options:[
            {key: 107, text: "Very unconfident", value:107, question_id: 15, score: 0,left:"0%",color:"#DA291C"},
            {key: 108, text: "Somewhat unconfident", value:108, question_id: 15, score: 2.5,left:"25%",color:"#FF8200"},
            {key: 109, text: "Neither confident nor unconfident", value:109, question_id:15, score: 5,left:"50%",color:"#FFCD00"},
            {key: 110, text: "Somewhat confident", value:110, question_id: 15, score: 7.5,left:"75%",color:"#A8DD66"},
            {key: 111, text: "Very confident", value:111, question_id: 15, score: 10,left:"100%",color:"#78BE20"},
        ]
    },
    {
        id:16,
        question:"When it comes to saving for your retirement, are your plans on track?",
        options:[
            {key: 112, text: "Completely on track", value:112, question_id: 16, score: 10,left:"0%",color:"#DA291C"},
            {key: 113, text: "Somewhat on track", value:113, question_id:16, score: 5,left:"25%",color:"#FF8200"},
            {key: 114, text: "Not on track ", value:114, question_id:16, score: 0,left:"50%",color:"#FFCD00"},
            {key: 115, text: "I don’t know ", value:115, question_id: 16, score: -3,left:"75%",color:"#A8DD66"},
            {key: 116, text: "I have no retirement plans", value:116, question_id: 16, score: -5,left:"100%",color:"#78BE20"},
        ]
    },
]