import React,{Component} from 'react';
import {Form,Radio} from 'semantic-ui-react';
class RadioSelect extends Component{
    render(){
        return(
            <div className="radio-select">
                {this.props.options.map((option,index)=>{
                    return(
                        <Form.Field key={index}>
                            <Radio
                                score={option.score}
                                label={option.text}
                                value={option.value}
                                checked={this.props.selectedAnswer === option.value}
                                onChange={this.props.handleSelect}
                            />
                        </Form.Field>
                    )
                })}

            </div>
        )
    }
}
export default RadioSelect;