// export const BASE_URL = ' http://165.22.120.255/api';
export const BASE_URL = 'https://wellbeing.closebrothersam.com/api';
// export const BASE_URL = 'https://closebrothers-dev-api.digiryte.co.uk/api';
// export const BASE_URL = 'http://localhost:3000/api';
export const GET_DATA = BASE_URL+"/questions";
export const GET_SECTORS = BASE_URL+"/view_sectors";
export const START_SURVEY = BASE_URL+"/start_survey";
export const SAVE_ANSWERS = BASE_URL+"/save_answers";
export const GET_SCORES = BASE_URL+"/scores";
export const VERIFY_ORGANISATION = BASE_URL+"/verify_organisation";
export const PDF_DOWNLOAD_COUNT = BASE_URL+"/pdf_count"

