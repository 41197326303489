import React,{Component} from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import {Form,Radio} from 'semantic-ui-react';
class RangeSliderWithRadio extends Component{

    render() {
        const min = this.props.min || 0;
        const max = this.props.max || 100;
        return(
            <div className="range-slider">
                <div className="heading">
                    {this.props.selectedLabel&& this.props.selectedLabel !== "N/A. I have no monthly housing costs" &&
                    this.props.selectedLabel !== "Don't know" &&
                    <span className="value" style={{float:"none"}}>{this.props.selectedLabel}</span>}
                </div>
                <Slider
                    min={min}
                    max={max}
                    value={this.props.sliderValue}
                    orientation="horizontal"
                    onChange={this.props.handleSelect}
                    tooltip={false}
                    step={this.props.step}
                    labels={{5:"<10%",40:"45-50%",80:">100%"}}
                />
                <div style={{height:'45px'}}></div>
                <Form.Field>
                    <Radio
                        label='N/A. I have no monthly housing costs'
                        value='N/A. I have no monthly housing costs'
                        checked={this.props.selectedLabel === 'N/A. I have no monthly housing costs'}
                        onChange={(e,{value})=>{
                            e.preventDefault();
                            this.props.handleSelect(value,"radio");
                        }}
                    />
                </Form.Field>
                <div style={{height:'15px'}}></div>
                <Form.Field>
                    <Radio
                        label="Don't know"
                        value="Don't know"
                        checked={this.props.selectedLabel === "Don't know"}
                        onChange={(e,{value})=>{
                            e.preventDefault();
                            this.props.handleSelect(value,"radio");
                        }}
                    />
                </Form.Field>

            </div>
        )
    }
}
export default RangeSliderWithRadio;