import React,{Component} from 'react';
import {Button} from "semantic-ui-react";
class ButtonSelect extends Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <div>
                {this.props.options.map((option,index)=>{
                    return(
                        <Button primary key={index} className={this.props.selectedAnswer === option.value?"active":""} onClick={(e)=>{
                            e.preventDefault();
                            this.props.handleSelect(option.value,option.score);
                        }}>{option.text}</Button>
                    )
                })}
            </div>
        )
    }
}
export default ButtonSelect;