import React, { Component } from "react";

// Styling
const styles = {
  container: {
    // width: "180px",
    // height: " 85px",
    width: "11.25rem",
    // height: " 4.6875rem",
    // height: window.innerWidth > 1322 ? "4.6875rem" : "6rem",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    boxShadow: `0 13px 27px -5px hsl(240deg 30% 28% / 25%), 0 8px 16px -8px hsl(0deg 0% 0% / 30%), 0 -6px 16px -6px hsl(0deg 0% 0% / 3%)`,
    // margin: "0 20px 20px 0",
    flexDirection: "column",
  },

  title: {
    marginBottom: "5px",
    textAlign: "center",
  },

  score: {
    // position: "absolute",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
};

// component
class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSmallScreen: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }
  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isSmallScreen: window.innerWidth > 1230 });
  }

  render() {
    const { title, score, borderColor, idx } = this.props;
    return (
      <div
        style={{
          ...styles.container,
          borderLeft: `5px solid ${borderColor}`,
          marginRight: idx === 3 || idx === 7 ? "0px !important" : 20,
          marginBottom: 20,
          height: this.state.isSmallScreen > 1322 ? "4.6875rem" : "6rem",
        }}
      >
        <p style={styles.title}>{title}</p>
        <span style={styles.score}>{score}</span>
      </div>
    );
  }
}

export default Card;
