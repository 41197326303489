import React, {Component} from 'react';
import {Button, Grid, GridColumn, Image} from "semantic-ui-react";
import * as logo from '../../../assets/logo_white.png';
import SectionList from "../general/sectionList";
import ScoreCard from "../general/scoreCard";
import ButtonRangeWithNoOption from "../general/buttonRangeWithNoOption";
import {savingsAndInvestmentsQuestions} from "../../../utils/savingsAndInvestmentsSection";
import PageDimmer from "../general/pageDimmer";
import {scrollSmoothToBottom, scrollSmoothToTop} from "../../../utils/scrollSmooth";

class Section5 extends Component{
    constructor(props){
        super(props);
        this.state = {
            activeQuestion:1,
            scrollPosition:1,
            selectedAnswerForQuestion1:{
                answered:false,
                shortTermGoals:null,
                mediumTermGoals:null,
                longTermGoals:null
            },
            scoreForQuestion1:{
                shortTermGoals:0,
                mediumTermGoals:0,
                longTermGoals:0,
            },
            selectedAnswerForQuestion2:{
                shortTermGoals:null,
                mediumTermGoals:null,
                longTermGoals:null
            },
            scoreForQuestion2:{
                shortTermGoals:null,
                mediumTermGoals:null,
                longTermGoals:null,
            },
            disableBlur:false,
            allQuestionsAnswered:false,
            timerCount:0,
        };
        this.question1ref = React.createRef();
        this.question2ref = React.createRef();
        let totalScore = 0;
        let questionOneTotalScore = 0;
        let questionTwoTotalScore = 0;
        let questionOneAnswered = 0;
        let questionTwoAnswered = 0;
    }

    componentWillMount() {
        if (this.props.sectionAnswers) {
            const [selectedAnswerForQuestion1, selectedAnswerForQuestion2] = this.props.answers;
            const [scoreForQuestion1, scoreForQuestion2] = this.props.sectionScores;
            this.setState({
                selectedAnswerForQuestion1,
                selectedAnswerForQuestion2,
                scoreForQuestion1,
                scoreForQuestion2,
                disableBlur: true,
                activeQuestion: 2,
                update: true,
                allQuestionsAnswered: true
            });
        }
    }

    handleSelectAnswerForQuestion1 = (term,value,score)=>{
        let shortTermGoals = this.state.selectedAnswerForQuestion1.shortTermGoals;
        let mediumTermGoals = this.state.selectedAnswerForQuestion1.mediumTermGoals;
        let longTermGoals = this.state.selectedAnswerForQuestion1.longTermGoals;
        let scoreForQuestion1 = this.state.scoreForQuestion1;
        if(term === "short_term"){
            shortTermGoals = value;
            scoreForQuestion1.shortTermGoals = score;
        }
        if(term === "medium_term"){
            mediumTermGoals = value;
            scoreForQuestion1.mediumTermGoals = score;
        }
        if(term === "long_term"){
            longTermGoals = value;
            scoreForQuestion1.longTermGoals = score;
        }
        if(shortTermGoals && mediumTermGoals && longTermGoals 
            // && ((this.state.scoreForQuestion1.shortTermGoals + this.state.scoreForQuestion1.mediumTermGoals + this.state.scoreForQuestion1.longTermGoals) !== -7.5)
            ){
            this.setState({
                activeQuestion:2,
                scrollPosition:2
            });
            scrollSmoothToTop(this.question2ref.current.offsetTop + 100,this.question1ref.current);
            this.questionOneAnswered = 1;
        }
        // if(((this.state.scoreForQuestion1.shortTermGoals + this.state.scoreForQuestion1.mediumTermGoals + this.state.scoreForQuestion1.longTermGoals) === -7.5)){
        //     this.setState({allQuestionsAnswered:true});
        // }

        this.setState({
            selectedAnswerForQuestion1:{
                answered:true,
                shortTermGoals:shortTermGoals,
                mediumTermGoals:mediumTermGoals,
                longTermGoals:longTermGoals
            },
            scoreForQuestion1:scoreForQuestion1,
        });

    };
    handleSelectAnswerForQuestion2 = (term,value,score)=>{
        let shortTermGoals = this.state.selectedAnswerForQuestion2.shortTermGoals;
        let mediumTermGoals = this.state.selectedAnswerForQuestion2.mediumTermGoals;
        let longTermGoals = this.state.selectedAnswerForQuestion2.longTermGoals;
        let scoreForQuestion2 = this.state.scoreForQuestion2;

        if(term === "short_term"){
            shortTermGoals = value;
            scoreForQuestion2.shortTermGoals = score;
        }
        if(term === "medium_term"){
            mediumTermGoals = value;
            scoreForQuestion2.mediumTermGoals = score;
        }
        if(term === "long_term"){
            longTermGoals = value;
            scoreForQuestion2.longTermGoals = score;
        }

        let scoreForQuestion1 = this.state.scoreForQuestion1;
        let flag = 1;
        if(scoreForQuestion1.shortTermGoals >=0 && shortTermGoals === null){
            flag = 0;
        }
        if(scoreForQuestion1.mediumTermGoals >=0 && mediumTermGoals === null){
            flag = 0;
        }
        if(scoreForQuestion1.longTermGoals >=0 && longTermGoals === null){
            flag = 0;
        }
        this.questionTwoAnswered = flag;

        this.setState({
            selectedAnswerForQuestion2:{
                shortTermGoals:shortTermGoals,
                mediumTermGoals:mediumTermGoals,
                longTermGoals:longTermGoals
            },
            scoreForQuestion2:scoreForQuestion2,
        });
        
         if((this.state.scoreForQuestion2.shortTermGoals !== null  && this.state.scoreForQuestion2.mediumTermGoals !== null && this.state.scoreForQuestion2.longTermGoals !== null) && (this.state.scoreForQuestion2.shortTermGoals.toString() && this.state.scoreForQuestion2.mediumTermGoals.toString() && this.state.scoreForQuestion2.longTermGoals.toString())){
            this.setState({allQuestionsAnswered:true});
        }

        if((this.state.scoreForQuestion1.shortTermGoals !== -2.5 ? shortTermGoals:true) && (this.state.scoreForQuestion1.mediumTermGoals !== -2.5 ? mediumTermGoals:true) && (this.state.scoreForQuestion1.longTermGoals !== -2.5 ? longTermGoals:true) ){
            // this.setState({allQuestionsAnswered:true,disableBlur:true});
            // this.question1ref.current.parentNode.scrollTo({top:0,behavior:"smooth"});
        }
    };
    showQuestion2 = () =>{
        // if((this.state.selectedAnswerForQuestion1.shortTermGoals &&
        //     this.state.selectedAnswerForQuestion1.mediumTermGoals &&
        //     this.state.selectedAnswerForQuestion1.longTermGoals)&&
        //     (this.state.selectedAnswerForQuestion1.shortTermGoals !== savingsAndInvestmentsQuestions[0].shortTermNa.value||
        //     this.state.selectedAnswerForQuestion1.mediumTermGoals !==savingsAndInvestmentsQuestions[0].mediumTermNa.value||
        //     this.state.selectedAnswerForQuestion1.longTermGoals !==savingsAndInvestmentsQuestions[0].longTermNa.value)){
        //     return true;
        // }
        // else{
        //     return false;
        // }
        return true
    };

    handleSelectedScroll = (q) => {
        const currentScrollPosition = this.state.scrollPosition;
        if (q === 1) {
            if (currentScrollPosition > q) {
                scrollSmoothToBottom(this.question2ref.current.offsetTop, this.question1ref.current);
            }
            this.setState({scrollPosition: 1});
        }
        if (q === 2) {
            if (currentScrollPosition > q) {
                scrollSmoothToBottom(this.question2ref.current.offsetTop - this.question1ref.current.offsetTop , this.question2ref.current);
            } else {
                scrollSmoothToTop(this.question2ref.current.offsetTop - this.question1ref.current.offsetTop + 115, this.question2ref.current);
            }
            this.setState({scrollPosition: 2});
        }
    };


    handleSubmit = (e)=>{
        e.preventDefault();
        let answerIds = [];
        let answers = [];
        let scores = [];
        let selectedAnswerForQuestion1 = this.state.selectedAnswerForQuestion1;
        let selectedAnswerForQuestion2 = this.state.selectedAnswerForQuestion2;
        let scoreForQuestion1 = this.state.scoreForQuestion1;
        let scoreForQuestion2 = this.state.scoreForQuestion2;
        answers.push(selectedAnswerForQuestion1);
        answers.push(selectedAnswerForQuestion2);
        scores.push(this.state.scoreForQuestion1);
        scores.push(this.state.scoreForQuestion2);
        answerIds.push(selectedAnswerForQuestion1.shortTermGoals);
        answerIds.push(selectedAnswerForQuestion1.mediumTermGoals);
        answerIds.push(selectedAnswerForQuestion1.longTermGoals);
        answerIds.push(selectedAnswerForQuestion2.shortTermGoals);
        answerIds.push(selectedAnswerForQuestion2.mediumTermGoals);
        answerIds.push(selectedAnswerForQuestion2.longTermGoals);
        let filteredAnswerIds = answerIds.filter(function (el) {
            return el != null;
        });
        let scoreArray = [];
        scoreArray.push(scoreForQuestion1.shortTermGoals);
        scoreArray.push(scoreForQuestion1.mediumTermGoals);
        scoreArray.push(scoreForQuestion1.longTermGoals);
        if(scoreForQuestion1.shortTermGoals >= 0){
            scoreArray.push(scoreForQuestion2.shortTermGoals);
        }
        if(scoreForQuestion1.mediumTermGoals >=0){
            scoreArray.push(scoreForQuestion2.mediumTermGoals);
        }
        if(scoreForQuestion1.longTermGoals >=0){
            scoreArray.push(scoreForQuestion2.longTermGoals);
        }
        let totalScore = 0;
        for(let i in scoreArray){
            totalScore+=scoreArray[i];
        }
        this.totalScore = (totalScore/(scoreArray.length*10))*100;
        this.setState({showScore:true});
        let countdown = setInterval(()=>{
            this.setState({timerCount:this.state.timerCount+1});
            if(this.state.timerCount > 3){
                clearInterval(countdown);
                this.props.handleSubmit(filteredAnswerIds,this.totalScore,scores,answers);
            }
        },1000);
    };

    render(){
        const {activeQuestion,scrollPosition} = this.state;
        const {orgLogo} = this.props;

        return(
            <div>
                <PageDimmer showScore={this.state.showScore} timerCount={this.state.timerCount} content={"Loading next section"} sections={true}/>
                <Grid className="section-main-div">
                    <GridColumn width={3} className="section-details">
                        <Image src={orgLogo||logo}/>
                        <SectionList activeSection={5}/>
                        <ScoreCard sectionName="Savings & investments" score={this.totalScore} showScore={this.state.showScore}/>
                    </GridColumn>
                    <GridColumn width={13} className="lens-div">
                        <div className="lens">
                            <Image src="/web_lens_full.png" onLoad={()=>{this.setState({loader:false})}}/>
                        </div>
                        <div className="nav-indicator">
                            <span className={(scrollPosition === 1 || !this.showQuestion2()) ? "dot active-5 section-5" : "dotNav section-5"}
                                  onClick={() => activeQuestion >= 1 ? this.handleSelectedScroll(1) : null}></span>{this.showQuestion2() && <span
                            className={scrollPosition === 2 ? "dot active-5 section-5" : "dotNav section-5"}
                            onClick={() => activeQuestion >= 2 ? this.handleSelectedScroll(2) : null}></span>}
                        </div>
                        <div className="questions-list" onScroll={this.handleScroll} style={this.state.allQuestionsAnswered?{height:'36em',top:'15%'}: this.showQuestion2()?{height:'36em',top:'15%'}:{height:'36em',top:'15%'}}>
                            <div className={"question-item "+(activeQuestion >=1 || this.state.disableBlur?"":"blur")} ref={this.question1ref}>
                                <div className="question">
                                    <span>1.</span><span>{savingsAndInvestmentsQuestions[0].question}</span>
                                </div>
                                <div className="answer">
                                    <ButtonRangeWithNoOption shortTermGoalOptions={savingsAndInvestmentsQuestions[0].shortTermGoalOptions}
                                                             mediumTermGoalOptions={savingsAndInvestmentsQuestions[0].mediumTermGoalOptions}
                                                             longTermGoalOptions={savingsAndInvestmentsQuestions[0].longTermGoalOptions}
                                                             shortTermNa={savingsAndInvestmentsQuestions[0].shortTermNa}
                                                             mediumTermNa={savingsAndInvestmentsQuestions[0].mediumTermNa}
                                                             longTermNa={savingsAndInvestmentsQuestions[0].longTermNa}
                                                             selectedAnswer={this.state.selectedAnswerForQuestion1}
                                                             handleSelect={this.handleSelectAnswerForQuestion1}
                                                             showShortTermGoals={true}
                                                             showMediumTermGoals={true}
                                                             showLongTermGoals={true}
                                    />
                                </div>
                            </div>
                            <div ref={this.question2ref} style={{height:(this.showQuestion2() || this.state.allQuestionsAnswered)?"1em":"1em"}}></div>
                            {this.showQuestion2() && <div className={"question-item "+(activeQuestion >=2?"":"blur")}>
                                <div className="question">
                                    <span>2.</span><span> How confident are you in choosing the most suitable financial product(s) for the following savings goals?</span>
                                </div>
                                <div className="answer">
                                    <ButtonRangeWithNoOption shortTermGoalOptions={savingsAndInvestmentsQuestions[1].shortTermGoalOptions}
                                                             mediumTermGoalOptions={savingsAndInvestmentsQuestions[1].mediumTermGoalOptions}
                                                             longTermGoalOptions={savingsAndInvestmentsQuestions[1].longTermGoalOptions}
                                                             shortTermNa={savingsAndInvestmentsQuestions[1].shortTermNa}
                                                             mediumTermNa={savingsAndInvestmentsQuestions[1].mediumTermNa}
                                                             longTermNa={savingsAndInvestmentsQuestions[1].longTermNa}
                                                             selectedAnswer={this.state.selectedAnswerForQuestion2}
                                                             handleSelect={this.handleSelectAnswerForQuestion2}
                                                            //  showShortTermGoals={this.state.selectedAnswerForQuestion1.shortTermGoals !==savingsAndInvestmentsQuestions[0].shortTermNa.value}
                                                            //  showMediumTermGoals={this.state.selectedAnswerForQuestion1.mediumTermGoals !==savingsAndInvestmentsQuestions[0].mediumTermNa.value}
                                                            //  showLongTermGoals={this.state.selectedAnswerForQuestion1.longTermGoals !==savingsAndInvestmentsQuestions[0].longTermNa.value}
                                                             showShortTermGoals={true}
                                                             showMediumTermGoals={true}
                                                             showLongTermGoals={true}
                                    />
                                </div>
                            </div>}
                            {(this.state.allQuestionsAnswered && !this.state.showScore) &&
                            <div className="next-button">
                                <Button onClick={this.handleSubmit}>{this.state.update ? "Update" : "Next"}</Button>
                            </div>}
                            <div style={{height:this.showQuestion2()?'36em':(!this.state.allQuestionsAnswered ?'26em':'10px')}}></div>
                        </div>
                    </GridColumn>
                </Grid>
            </div>
        )
    }
}
export default Section5;