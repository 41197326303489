import React, { Component } from "react";
import {
  Button,
  Grid,
  GridColumn,
  Icon,
  Image,
  Popup,
} from "semantic-ui-react";
import * as logo from "../../../assets/logo_white.png";
import SectionList from "../general/sectionList";
import ScoreCard from "../general/scoreCard";
import ButtonRange from "../general/buttonRange";
import YesOrNoWithDropdown from "../general/yesOrNoWithDropdown";
import { budgetingAndPlanningQuestions } from "../../../utils/budgetingAndPlanningQuestions";
import PageDimmer from "../general/pageDimmer";
import {
  scrollSmoothToBottom,
  scrollSmoothToTop,
} from "../../../utils/scrollSmooth";

class Section2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeQuestion: 1,
      scrollPosition: 1,
      selectedAnswerForQuestion1: null,
      scoreForQuestion1: 0,
      selectedAnswerForQuestion2: null,
      scoreForQuestion2: 0,
      selectedAnswerForQuestion3: null,
      scoreForQuestion3: 0,
      disableBlur: false,
      allQuestionsAnswered: false,
      timerCount: 0,
      contentHeight: "25em",
    };
    this.question1ref = React.createRef();
    this.question2ref = React.createRef();
    this.question3ref = React.createRef();
    let totalScore = 0;
  }

  componentWillMount() {
    if (this.props.sectionAnswers) {
      const selectedOption = budgetingAndPlanningQuestions[0].yesOptions.concat(
        budgetingAndPlanningQuestions[0].noOptions
      );
      const [
        selectedAnswerForQuestion1,
        selectedAnswerForQuestion2,
        selectedAnswerForQuestion3,
      ] = this.props.sectionAnswers;
      const [scoreForQuestion1, scoreForQuestion2, scoreForQuestion3] =
        this.props.sectionScores;
      let { display_text } = selectedOption.find(
        (o) => o.value === selectedAnswerForQuestion1
      );
      this.setState({
        selectedAnswerForQuestion1,
        selectedAnswerForQuestion2,
        selectedAnswerForQuestion3,
        scoreForQuestion1,
        scoreForQuestion2,
        scoreForQuestion3,
        displaySelectedAnswer: display_text,
        disableBlur: true,
        activeQuestion: 3,
        update: true,
        allQuestionsAnswered: true,
      });
    }
  }

  handleSelectAnswerForQuestion1 = (option, data) => {
    const { value } = data;
    const { score, display_text } = data.options.find((o) => o.value === value);
    this.setState({
      selectedAnswerForQuestion1: value,
      displaySelectedAnswer: display_text,
      scoreForQuestion1: score,
      activeQuestion: 2,
      scrollPosition: 2,
    });
    scrollSmoothToTop(
      this.question2ref.current.offsetTop + 100,
      this.question1ref.current
    );
  };

  handleSelectAnswerForQuestion2 = (option, score) => {
    this.setState({
      selectedAnswerForQuestion2: option,
      scoreForQuestion2: score,
      activeQuestion: 3,
      scrollPosition: 3,
    });
    scrollSmoothToTop(
      this.question3ref.current.offsetTop -
        this.question2ref.current.offsetTop +
        30,
      this.question2ref.current
    );
  };

  handleSelectAnswerForQuestion3 = (option, score) => {
    this.setState({
      selectedAnswerForQuestion3: option,
      scoreForQuestion3: score,
      disableBlur: true,
      allQuestionsAnswered: true,
    });
    // this.question1ref.current.parentNode.scrollTo({top:0,behavior:"smooth"});
  };

  handleSelectedScroll = (q) => {
    const currentScrollPosition = this.state.scrollPosition;
    if (q === 1) {
      if (currentScrollPosition > q) {
        scrollSmoothToBottom(
          this.question3ref.current.offsetTop,
          this.question1ref.current
        );
      }
      this.setState({ scrollPosition: 1 });
    }
    if (q === 2) {
      if (currentScrollPosition > q) {
        scrollSmoothToBottom(
          this.question3ref.current.offsetTop -
            this.question2ref.current.offsetTop,
          this.question2ref.current
        );
      } else {
        scrollSmoothToTop(
          this.question2ref.current.offsetTop,
          this.question2ref.current
        );
      }
      this.setState({ scrollPosition: 2 });
    }
    if (q === 3) {
      if (currentScrollPosition > q) {
        scrollSmoothToBottom(
          this.question2ref.current.offsetTop + 30,
          this.question3ref.current
        );
      } else {
        scrollSmoothToTop(
          this.question2ref.current.offsetTop + 30,
          this.question3ref.current
        );
      }
      this.setState({ scrollPosition: 3 });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let answerIds = [];
    let scores = [];
    answerIds.push(this.state.selectedAnswerForQuestion1);
    answerIds.push(this.state.selectedAnswerForQuestion2);
    answerIds.push(this.state.selectedAnswerForQuestion3);
    scores.push(this.state.scoreForQuestion1);
    scores.push(this.state.scoreForQuestion2);
    scores.push(this.state.scoreForQuestion3);
    this.totalScore =
      ((this.state.scoreForQuestion1 +
        this.state.scoreForQuestion2 +
        this.state.scoreForQuestion3) /
        30) *
      100;
    this.setState({ showScore: true });
    let countdown = setInterval(() => {
      this.setState({ timerCount: this.state.timerCount + 1 });
      if (this.state.timerCount > 3) {
        clearInterval(countdown);
        this.props.handleSubmit(answerIds, this.totalScore, scores);
      }
    }, 1000);
  };

  render() {
    const { activeQuestion, scrollPosition } = this.state;
    const { orgLogo } = this.props;
    return (
      <div>
        <PageDimmer
          showScore={this.state.showScore}
          timerCount={this.state.timerCount}
          content={"Loading next section"}
          sections={true}
        />
        <Grid className="section-main-div">
          <GridColumn width={3} className="section-details">
            <Image src={orgLogo || logo} />
            <SectionList activeSection={2} />
            <ScoreCard
              sectionName="Budgeting & planning"
              score={this.totalScore}
              showScore={this.state.showScore}
            />
          </GridColumn>
          <GridColumn width={13} className="lens-div">
            <div className="lens">
              <Image
                src="/web_lens_full.png"
                onLoad={() => {
                  this.setState({ loader: false });
                }}
              />
            </div>
            <div className="nav-indicator">
              <span
                className={
                  scrollPosition === 1
                    ? "dot active-2 section-2"
                    : "dotNav section-2"
                }
                onClick={() =>
                  activeQuestion >= 1 ? this.handleSelectedScroll(1) : null
                }
              ></span>
              <span
                className={
                  scrollPosition === 2
                    ? "dot active-2 section-2"
                    : "dotNav section-2"
                }
                onClick={() =>
                  activeQuestion >= 2 ? this.handleSelectedScroll(2) : null
                }
              ></span>
              <span
                className={
                  scrollPosition === 3
                    ? "dot active-2 section-2"
                    : "dotNav section-2"
                }
                onClick={() =>
                  activeQuestion >= 3 ? this.handleSelectedScroll(3) : null
                }
              ></span>
            </div>
            <div
              className="questions-list"
              style={{ height: "25em", top: "35%" }}
            >
              <div
                className={
                  "question-item " +
                  (activeQuestion >= 1 || this.state.disableBlur ? "" : "blur")
                }
                ref={this.question1ref}
              >
                <div className="question">
                  <span>1.</span>
                  <span> {budgetingAndPlanningQuestions[0].question}</span>
                </div>
                <div className="answer">
                  <YesOrNoWithDropdown
                    handleSelect={this.handleSelectAnswerForQuestion1}
                    selectedAnswer={this.state.selectedAnswerForQuestion1}
                    showAnswer={this.state.displaySelectedAnswer}
                    yesOptions={budgetingAndPlanningQuestions[0].yesOptions}
                    noOptions={budgetingAndPlanningQuestions[0].noOptions}
                  />
                </div>
              </div>
              <div
                className={
                  "question-item " +
                  (activeQuestion >= 2 || this.state.disableBlur ? "" : "blur")
                }
                ref={this.question2ref}
                style={{ paddingBottom: "40px" }}
              >
                <div className="question">
                  <span>2.</span>
                  <span>
                    {" "}
                    How confident do you feel about making financial decisions?
                  </span>
                </div>
                <div className="answer">
                  <ButtonRange
                    options={budgetingAndPlanningQuestions[1].options}
                    selectedAnswer={this.state.selectedAnswerForQuestion2}
                    handleSelect={this.handleSelectAnswerForQuestion2}
                  />
                </div>
              </div>
              <div
                className={
                  "question-item " +
                  (activeQuestion >= 3 || this.state.disableBlur ? "" : "blur")
                }
                ref={this.question3ref}
              >
                <div className="question">
                  <span>3.</span>
                  <span>
                    How often do you review your budget ie your income and
                    expenditure?
                  </span>
                </div>
                <div className="answer">
                  <ButtonRange
                    options={budgetingAndPlanningQuestions[2].options}
                    selectedAnswer={this.state.selectedAnswerForQuestion3}
                    handleSelect={this.handleSelectAnswerForQuestion3}
                  />
                </div>
              </div>
              {this.state.allQuestionsAnswered && !this.state.showScore && (
                <div className="next-button">
                  <Button onClick={this.handleSubmit}>
                    {this.state.update ? "Update" : "Next"}
                  </Button>
                </div>
              )}
              <div
                style={{
                  height: this.state.allQuestionsAnswered ? "230px" : "350px",
                }}
              ></div>
            </div>
          </GridColumn>
        </Grid>
      </div>
    );
  }
}

export default Section2;
