/*Properties and mortgages section questions*/
export const propertiesAndMortgagesQuestions = [
    {
        id:12,
        question:"Approximately what percentage of your monthly income is required to cover your monthly housing costs?",
        sliderOptions:[
            {key: 92, text: "N/A. I have no monthly housing costs", value:92, question_id: 12, score: 10},
            {key: 93, text: "1-15%", value:93, question_id: 93, score: 9},
            {key: 94, text: "16-30%", value:94, question_id: 94, score: 8},
            {key: 95, text: "31-45%", value:95, question_id: 95, score: 5},
            {key: 96, text: "46-50%", value:96, question_id: 96, score: 3},
            {key: 97, text: "51-60%", value:97, question_id: 97, score: 2},
            {key: 98, text: "61-70%", value:98, question_id: 98, score: 1},
            {key: 99, text: "71-100%", value:99, question_id: 99, score: 0},
            {key: 100, text: ">100%", value:100, question_id: 100, score: -10},
            {key: 101, text: "Don't know", value:101, question_id: 101, score: 0},
        ]
    },
    {
        id:13,
        question:"If you are intending to buy a property in the future, how confident are you in choosing the most suitable saving products?",
        options:[
            {key: 102, text: "NA", value:102, question_id: 13, score: 10,left:"0%",color:"#DA291C"},
            {key: 103, text: "Very confident", value:103, question_id: 13, score: 10,left:"20%",color:"#FF8200"},
            {key: 104, text: "Somewhat confident", value:104, question_id:13, score: 8,left:"40%",color:"#FFCD00"},
            {key: 105, text: "Neither confident/unconfident", value:105, question_id: 13, score: 5,left:"60%",color:"#A8DD66"},
            {key: 106, text: "Somewhat unconfident", value:106, question_id: 13, score: 3,left:"80%",color:"#78BE20"},
            {key: 107, text: "Very unconfident", value:107, question_id: 13, score: 0,left:"100%",color:"#78BE20"},
        ]
    },
    {
        id:14,
        question:"If you expect to arrange a mortgage in the future for your first property, to re-mortgage your existing property or for an additional property, how confident are you in choosing the most suitable mortgage and mortgage provider?",
        options:[
            {key: 108, text: "NA", value:108, question_id: 14, score: 10,left:"0%",color:"#DA291C"},
            {key: 109, text: "Very confident", value:109, question_id: 14, score: 10,left:"20%",color:"#FF8200"},
            {key: 110, text: "Somewhat confident", value:110, question_id:14, score: 8,left:"40%",color:"#FFCD00"},
            {key: 111, text: "Neither confident/unconfident", value:111, question_id: 14, score: 5,left:"60%",color:"#A8DD66"},
            {key: 112, text: "Somewhat unconfident", value:112, question_id: 14, score: 3,left:"80%",color:"#78BE20"},
            {key: 113, text: "Very unconfident", value:113, question_id: 14, score: 0,left:"100%",color:"#78BE20"},
        ]
    },
]
