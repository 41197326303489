import React, {Component} from 'react';
import {Button, Grid, GridColumn, Image} from "semantic-ui-react";
import * as logo from '../../../assets/logo_white.png';
import SectionList from "../general/sectionList";
import ScoreCard from "../general/scoreCard";
import RadioSelect from "../general/radioSelect";
import ButtonRange from "../general/buttonRange";
import {debtQuestions} from "../../../utils/debtQuestions";
import PageDimmer from "../general/pageDimmer";
import {scrollSmoothToBottom, scrollSmoothToTop} from "../../../utils/scrollSmooth";

class Section3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeQuestion: 1,
            scrollPosition: 1,
            selectedAnswerForQuestion1: null,
            scoreForQuestion1: 0,
            selectedAnswerForQuestion2: null,
            scoreForQuestion2: 0,
            disableBlur: false,
            allQuestionsAnswered: false,
            timerCount: 0,
        };

        this.question1ref = React.createRef();
        this.question2ref = React.createRef();
        let totalScore = 0;
    }

    componentWillMount() {
        if (this.props.sectionAnswers) {
            const [selectedAnswerForQuestion1, selectedAnswerForQuestion2] = this.props.sectionAnswers;
            const [scoreForQuestion1, scoreForQuestion2] = this.props.sectionScores;
            this.setState({
                selectedAnswerForQuestion1,
                selectedAnswerForQuestion2,
                scoreForQuestion1,
                scoreForQuestion2,
                disableBlur: true,
                activeQuestion: 2,
                update: true,
                allQuestionsAnswered: true
            });
        }
    }

    handleSelectForQuestion1 = (option, data) => {
        this.setState({
            selectedAnswerForQuestion1: data.value,
            scoreForQuestion1: data.score,
            activeQuestion: 2,
            scrollPosition: 2
        });
        scrollSmoothToTop(this.question2ref.current.offsetTop, this.question1ref.current);
    };
    handleSelectAnswerForQuestion2 = (option, score) => {
        this.setState({
            selectedAnswerForQuestion2: option,
            scoreForQuestion2: score,
            disableBlur: true,
            allQuestionsAnswered: true,
        });
    };

    handleSelectedScroll = (q) => {
        const currentScrollPosition = this.state.scrollPosition;
        if (q === 1) {
            if (currentScrollPosition > q) {
                scrollSmoothToBottom(this.question2ref.current.offsetTop, this.question1ref.current);
            }
            this.setState({scrollPosition: 1});
        }
        if (q === 2) {
            if (currentScrollPosition > q) {
                scrollSmoothToBottom(this.question2ref.current.offsetTop - this.question1ref.current.offsetTop, this.question2ref.current);
            } else {
                scrollSmoothToTop(this.question2ref.current.offsetTop, this.question1ref.current);
            }
            this.setState({scrollPosition: 2});
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let answerIds = [];
        let scores = [];
        answerIds.push(this.state.selectedAnswerForQuestion1);
        answerIds.push(this.state.selectedAnswerForQuestion2);
        scores.push(this.state.scoreForQuestion1);
        scores.push(this.state.scoreForQuestion2);
        this.totalScore = ((this.state.scoreForQuestion1 + this.state.scoreForQuestion2) / 20) * 100;
        this.setState({showScore: true});
        let countdown = setInterval(() => {
            this.setState({timerCount: this.state.timerCount + 1});
            if (this.state.timerCount > 3) {
                clearInterval(countdown);
                this.props.handleSubmit(answerIds, this.totalScore,scores);
            }
        }, 1000);
    }

    render() {
        const {activeQuestion, scrollPosition} = this.state;
        const {orgLogo} = this.props;
        return (
            <div>
                <PageDimmer showScore={this.state.showScore} timerCount={this.state.timerCount}
                            content={"Loading next section"} sections={true}/>
                <Grid className="section-main-div">
                    <GridColumn width={3} className="section-details">
                        <Image src={orgLogo||logo}/>
                        <SectionList activeSection={3}/>
                        <ScoreCard sectionName="Debt" score={this.totalScore} showScore={this.state.showScore}/>
                    </GridColumn>
                    <GridColumn width={13} className="lens-div">
                        <div className="lens">
                            <Image src="/web_lens_full.png" onLoad={() => {
                                this.setState({loader: false})
                            }}/>
                        </div>
                        <div className="nav-indicator">
                            <span className={(scrollPosition === 1) ? "dot active-3 section-3" : "dotNav section-3"}
                                  onClick={() => activeQuestion >= 1 ? this.handleSelectedScroll(1) : null}></span><span
                            className={scrollPosition === 2 ? "dot active-3 section-3" : "dotNav section-3"}
                            onClick={() => activeQuestion >= 2 ? this.handleSelectedScroll(2) : null}></span>
                        </div>
                        <div className="questions-list" style={this.state.selectedAnswerForQuestion1 ? {
                            height: '25em',
                            top: '35%'
                        } : {height: '25em', top: '25%'}}>
                            <div
                                className={"question-item " + (activeQuestion >= 1 || this.state.disableBlur ? "" : "blur")}
                                ref={this.question1ref}>
                                <div className="question">
                                    <span>1.</span><span>{debtQuestions[0].question}</span>
                                </div>
                                <div className="answer">
                                    <RadioSelect options={debtQuestions[0].options}
                                                 selectedAnswer={this.state.selectedAnswerForQuestion1}
                                                 handleSelect={this.handleSelectForQuestion1}/>
                                </div>
                            </div>
                            <div
                                className={"question-item " + (activeQuestion >= 2 || this.state.disableBlur ? "" : "blur")}
                                ref={this.question2ref}>
                                <div className="question">
                                    <span>2.</span><span>{debtQuestions[1].question}</span>
                                </div>
                                <div className="answer">
                                    <ButtonRange options={debtQuestions[1].options}
                                                 selectedAnswer={this.state.selectedAnswerForQuestion2}
                                                 handleSelect={this.handleSelectAnswerForQuestion2}/>
                                </div>
                            </div>
                            {(this.state.allQuestionsAnswered && !this.state.showScore) &&
                            <div className="next-button">
                                <Button onClick={this.handleSubmit}>{this.state.update ? "Update" : "Next"}</Button>
                            </div>}
                            <div style={{height: this.state.allQuestionsAnswered ? '200px' : '250px'}}></div>
                        </div>
                    </GridColumn>

                </Grid>
            </div>
        )
    }
}

export default Section3;