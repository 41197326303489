/*Money worries section questions*/
export const moneyWorriesQuestions = [
    {
        id:1,
        question:"How do you feel about the current state of your personal finances?",
        options:[
            {key: 1, text: "Very unhappy", value:1, question_id: 1, score: 0,left:"0%",color:"#DA291C"},
            {key: 2, text: "Somewhat unhappy", value:2, question_id: 1, score: 2.5,left:"25%",color:"#FF8200"},
            {key: 3, text: "Neither happy nor unhappy", value:3, question_id: 1, score: 5,left:"50%",color:"#FFCD00"},
            {key: 4, text: "Somewhat happy", value:4, question_id: 1, score: 7.5,left:"75%",color:"#A8DD66"},
            {key: 5, text: "Very happy", value:5, question_id: 1, score: 10,left:"100%",color:"#78BE20"}
        ]
    },
    {
        id:2,
        question:"How often, do you worry about money, if at all?",
        options:[
            {key: 6, text: "Always", value:6, question_id: 2, score: 0},
            {key: 7, text: "Often", value:7, question_id: 2, score: 2.5},
            {key: 8, text: "Sometimes", value:8, question_id: 2, score: 5},
            {key: 9, text: "Rarely", value:9, question_id: 2, score: 7.5},
            {key: 10, text: "Never", value:10, question_id: 2, score: 10}
        ]
    },
    {
        id:3,
        question:"How much do money worries affect your work?",
        options:[
            {key: 11, text: "10", value:11, question_id: 3, score: 0,left:"0%",color:"#DA291C",label:"It affects me a lot"},
            {key: 12, text: "9", value:12, question_id: 3, score: 1,left:"10%",color:"#FA8765",label:"It affects me a lot"},
            {key: 13, text: "8", value:13, question_id: 3, score: 2,left:"20%",color:"#FA8765",label:"It affects me a lot"},
            {key: 14, text: "7", value:14, question_id: 3, score: 3,left:"30%",color:"#FF8200",label:"It affects me a lot"},
            {key: 15, text: "6", value:15, question_id: 3, score: 4,left:"40%",color:"#FF8200",label:"It affects me a lot"},
            {key: 16, text: "5", value:16, question_id: 3, score: 5,left:"50%",color:"#FFCD00",label:"It affects me somewhat"},
            {key: 17, text: "4", value:17, question_id: 3, score: 6,left:"60%",color:"#C7D14C",label:"It doesn’t affect me at all"},
            {key: 18, text: "3", value:18, question_id: 3, score: 7,left:"70%",color:"#C7D14C",label:"It doesn’t affect me at all"},
            {key: 19, text: "2", value:19, question_id: 3, score: 8,left:"80%",color:"#A3E056",label:"It doesn’t affect me at all"},
            {key: 20, text: "1", value:20, question_id: 3, score: 9,left:"90%",color:"#A3E056",label:"It doesn’t affect me at all"},
            {key: 21, text: "0", value:21, question_id: 3, score: 10,left:"100%",color:"#78BE20",label:"It doesn’t affect me at all"},

        ]
    }
]
