import React, {Component} from 'react';
import {Button, Grid, GridColumn, Image} from "semantic-ui-react";
import ButtonSelect from "../general/buttonSelect";
import * as logo from '../../../assets/logo_white.png';
import SectionList from "../general/sectionList";
import ScoreCard from "../general/scoreCard";
import ButtonRange from "../general/buttonRange";
import ButtonRangeWithNumbers from "../general/buttonRangeWithNumbers";
import {moneyWorriesQuestions} from "../../../utils/moneyWorriesQuestions";
import PageDimmer from "../general/pageDimmer";
import {scrollSmoothToBottom, scrollSmoothToTop} from "../../../utils/scrollSmooth";

class Section1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeQuestion: 1,
            scrollPosition: 1,
            selectedAnswerForQuestion1: null,
            scoreForQuestion1: 0,
            selectedAnswerForQuestion2: null,
            scoreForQuestion2: 0,
            selectedAnswerForQuestion3: null,
            scoreForQuestion3: 0,
            allQuestionsAnswered: false,
            section1Score: 0,
            loader: false,
            disableBlur: false,
            showScore: false,
            timerCount: 0,
            update: false
        };
        this.question1ref = React.createRef();
        this.question2ref = React.createRef();
        this.question3ref = React.createRef();
        let totalScore = 0;
    }

    componentWillMount() {
        if (this.props.sectionAnswers) {
            const [selectedAnswerForQuestion1, selectedAnswerForQuestion2, selectedAnswerForQuestion3] = this.props.sectionAnswers;
            const [scoreForQuestion1,scoreForQuestion2,scoreForQuestion3] = this.props.sectionScores;
            this.setState({
                selectedAnswerForQuestion1,
                selectedAnswerForQuestion2,
                selectedAnswerForQuestion3,
                scoreForQuestion1,
                scoreForQuestion2,
                scoreForQuestion3,
                disableBlur: true,
                activeQuestion: 3,
                update: true,
                allQuestionsAnswered: true
            });
        }
    }


    handleSelectAnswerForQuestion1 = (option, score) => {
        this.setState({
            selectedAnswerForQuestion1: option,
            activeQuestion: 2,
            scrollPosition: 2,
            scoreForQuestion1: score,
        });
        scrollSmoothToTop(this.question2ref.current.offsetTop, this.question1ref.current);
    };
    handleSelectAnswerForQuestion2 = (option, score) => {
        this.setState({
            selectedAnswerForQuestion2: option,
            activeQuestion: 3,
            scoreForQuestion2: score,
            contentHeight: '25em',
            scrollPosition: 3
        });
        scrollSmoothToTop(this.question3ref.current.offsetTop - this.question2ref.current.offsetTop, this.question2ref.current)
    };

    handleSelectAnswerForQuestion3 = (option, score) => {
        if (option) {
            this.setState({
                selectedAnswerForQuestion3: option,
                scoreForQuestion3: score,
                disableBlur: true,
                allQuestionsAnswered: true,
            });
        }
    };

    handleSelectedScroll = (q) => {
        const currentScrollPosition = this.state.scrollPosition;
        if (q === 1) {
            if (currentScrollPosition > q) {
                scrollSmoothToBottom(this.question3ref.current.offsetTop, this.question1ref.current);
            }
            this.setState({scrollPosition: 1});
        }
        if (q === 2) {
            if (currentScrollPosition > q) {
                scrollSmoothToBottom(this.question3ref.current.offsetTop - this.question2ref.current.offsetTop, this.question2ref.current);
            } else {
                scrollSmoothToTop(this.question3ref.current.offsetTop - this.question2ref.current.offsetTop, this.question2ref.current);
            }
            this.setState({scrollPosition: 2});
        }
        if (q === 3) {
            if (currentScrollPosition > q) {
                scrollSmoothToBottom(this.question3ref.current.offsetTop - this.question2ref.current.offsetTop, this.question2ref.current);
            } else {
                scrollSmoothToTop(this.question3ref.current.offsetTop - this.question2ref.current.offsetTop, this.question2ref.current);
            }
            this.setState({scrollPosition: 3});
        }
    };

    handleSection1Submit = (e) => {
        e.preventDefault();
        let answerIds = [];
        let scores = [];
        answerIds.push(this.state.selectedAnswerForQuestion1);
        answerIds.push(this.state.selectedAnswerForQuestion2);
        answerIds.push(this.state.selectedAnswerForQuestion3);
        scores.push(this.state.scoreForQuestion1);
        scores.push(this.state.scoreForQuestion2);
        scores.push(this.state.scoreForQuestion3);
        this.totalScore = ((this.state.scoreForQuestion1 + this.state.scoreForQuestion2 + this.state.scoreForQuestion3) / 30) * 100;
        this.setState({showScore: true});
        let countdown = setInterval(() => {
            this.setState({timerCount: this.state.timerCount + 1});
            if (this.state.timerCount > 4) {
                clearInterval(countdown);
                this.props.handleSubmit(answerIds, this.totalScore,scores);
            }
        }, 1000);
    };

    render() {
        const {activeQuestion, scrollPosition} = this.state;
        const {orgLogo} = this.props;
        return (
            <div>
                <PageDimmer showScore={this.state.showScore} timerCount={this.state.timerCount}
                            content={"Loading next section"} sections={true}/>
                {moneyWorriesQuestions.length > 0 &&
                <Grid className="section-main-div">
                    <GridColumn width={3} className="section-details">
                        <Image src={orgLogo||logo}/>
                        <SectionList activeSection={1}/>
                        <ScoreCard sectionName="Money worries" score={this.totalScore}
                                   showScore={this.state.showScore}/>
                    </GridColumn>
                    <GridColumn width={13} className="lens-div">
                        <div className="lens">
                            <Image src="/web_lens_full.png" onLoad={() => {
                                this.setState({loader: false})
                            }}/>
                        </div>
                        <div className="nav-indicator">
                            <span className={(scrollPosition === 1) ? "dot active-1 section-1" : "dotNav section-1"}
                                  onClick={() => activeQuestion >= 1 ? this.handleSelectedScroll(1) : null}></span><span
                            className={scrollPosition === 2 ? "dot active-1 section-1" : "dotNav section-1"}
                            onClick={() => activeQuestion >= 2 ? this.handleSelectedScroll(2) : null}></span><span
                            className={scrollPosition === 3 ? "dot active-1 section-1" : "dotNav section-1"}
                            onClick={() => activeQuestion >= 3 ? this.handleSelectedScroll(3) : null}></span>
                        </div>
                        <div className="questions-list">
                            <div
                                className={"question-item one " + (this.state.activeQuestion >= 1 || this.state.disableBlur ? "active" : "blur")}
                                id="1" ref={this.question1ref}>
                                <div className="question">
                                    <span>1.</span><span>{moneyWorriesQuestions[0].question}</span>
                                </div>
                                <div className="answer">
                                    <ButtonRange options={moneyWorriesQuestions[0].options}
                                                 selectedAnswer={this.state.selectedAnswerForQuestion1}
                                                 handleSelect={this.handleSelectAnswerForQuestion1}/>
                                </div>
                            </div>
                            <div style={{minHeight: '55px'}}></div>
                            <div
                                className={"question-item two " + (this.state.activeQuestion >= 2 || this.state.disableBlur ? "active" : "blur")}
                                id="2" ref={this.question2ref}>
                                <div className="question">
                                    <span>2.</span><span> {moneyWorriesQuestions[1].question}</span>
                                </div>
                                <div className="answer">
                                    <ButtonSelect selectedAnswer={this.state.selectedAnswerForQuestion2}
                                                  options={moneyWorriesQuestions[1].options}
                                                  handleSelect={this.handleSelectAnswerForQuestion2}/>
                                </div>
                            </div>
                            <div style={{minHeight: '55px'}}></div>
                            <div
                                className={"question-item three " + (this.state.activeQuestion >= 3 || this.state.disableBlur ? "active" : "blur")}
                                id="3" ref={this.question3ref}>
                                <div className="question">
                                    <span>3.</span><span> {moneyWorriesQuestions[2].question}</span>
                                </div>
                                <div className="answer">
                                    <ButtonRangeWithNumbers selectedAnswer={this.state.selectedAnswerForQuestion3}
                                                            options={moneyWorriesQuestions[2].options}
                                                            handleSelect={this.handleSelectAnswerForQuestion3}/>
                                </div>
                            </div>
                            {(this.state.allQuestionsAnswered && !this.state.showScore) &&
                            <div className="next-button">
                                <Button
                                    onClick={this.handleSection1Submit}>{this.state.update ? "Update" : "Next"}</Button>
                            </div>}
                            <div style={{height: '210px'}}></div>
                        </div>
                    </GridColumn>
                </Grid>
                }
            </div>
        )
    }
}

export default Section1;