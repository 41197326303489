import React,{Component} from 'react';
import {Tab,MenuItem,Dimmer,Loader,Popup} from 'semantic-ui-react';
import Section1 from "./sections/section1";
import Section2 from "./sections/section2";
import Section3 from "./sections/section3";
import Section4 from "./sections/section4";
import Section5 from "./sections/section5";
import Section6 from "./sections/section6";
import Section7 from "./sections/section7";
import Section8 from "./sections/section8";
import * as routes from "./constants/api_routes";
import axios from 'axios';
import ScoreChart from "./sections/scoreChart";
import "../../assets/stylesheets/navigation_dot.scss";


// const dummy_data = {
//     participant_overall_score: 61.35,
//     avg_overall_score: 60.01,
//     section_score: [
//       {
//         section_name: "Money worries",
//         participant_section_score: 35,
//         avg_section_score: 56.66
//       },
//       {
//         section_name: "Budgeting & planning",
//         participant_section_score: 83.33,
//         avg_section_score: 65.06
//       },
//       {
//         section_name: "Debt",
//         participant_section_score: 100,
//         avg_section_score: 66.83
//       },
//       {
//         section_name: "Protection",
//         participant_section_score: -2.5,
//         avg_section_score: 51.83
//       },
//       {
//         section_name: "Savings & investments",
//         participant_section_score: 100,
//         avg_section_score: 49.68
//       },
//       {
//         section_name: "Property & mortgages",
//         participant_section_score: 100,
//         avg_section_score: 79.23
//       },
//       {
//         section_name: "Retirement",
//         participant_section_score: 75,
//         avg_section_score: 62.5
//       },
//       {
//         section_name: "Tax",
//         participant_section_score: 0,
//         avg_section_score: 48.31
//       }
//     ]
//   };
class SurveyList extends Component{
    constructor(props){
        super(props);
        this.state = {
            activeTab:1,
            loader:false,
            moneyWorriesScore:0,
            budgetingAndPlanningScore:0,
            debtScore:0,
            protectionScore:0,
            savingsAndInvestmentsScore:0,
            propertiesAndMortgagesScore:0,
            retirementScore:0,
            taxScore:0,
            moneyWorriesQuestions:[],
            budgetingAndPlanningQuestions:[],
            debtQuestions:[],
            protectionQuestions:[],
            savingsAndInvestmentsQuestions:[],
            propertiesAndMortgagesQuestions:[],
            retirementQuestions:[],
            taxQuestions:[],
            answer_option_ids:[],
            scores:[],
            section5Answers:[],
            section6Additional:{},
            showFinalScore:false,
            maxSectionCompleted:0,
            open:false
        }
    }

    handleSection1Submit = (answer_option_ids,totalScore,scores)=>{
        const currentMax = this.state.maxSectionCompleted;
        if(this.state.answer_option_ids && this.state.answer_option_ids.length > 0){
            let answers = [...this.state.answer_option_ids];
            answers[0] = answer_option_ids;
            let score = [...this.state.scores];
            score[0] = scores;
            this.setState({answer_option_ids:answers,scores:score});
        }
        else {
            this.state.answer_option_ids.push(answer_option_ids);
            this.state.scores.push(scores);
        }
        this.setState({
            moneyWorriesScore:totalScore,
            activeTab:2,
            maxSectionCompleted:currentMax>1?currentMax:1,
            open:true
        });
        setTimeout(()=>{
            this.setState({open:false});
        },7000)
    };

    handleSection2Submit = (answer_option_ids,totalScore,scores)=>{
        const currentMax = this.state.maxSectionCompleted;
        if(this.state.answer_option_ids && this.state.answer_option_ids.length > 1){
            let answers = [...this.state.answer_option_ids];
            answers[1] = answer_option_ids;
            let score = [...this.state.scores];
            score[1] = scores;
            this.setState({answer_option_ids:answers,scores:score});
        }
        else {
            this.state.answer_option_ids.push(answer_option_ids);
            this.state.scores.push(scores);
        }
        this.setState({
            budgetingAndPlanningScore:totalScore,
            activeTab:3,
            maxSectionCompleted:currentMax>2?currentMax:2,
        });
    };

    handleSection3Submit = (answer_option_ids,totalScore,scores)=>{
        const currentMax = this.state.maxSectionCompleted;
        if(this.state.answer_option_ids && this.state.answer_option_ids.length > 2){
            let answers = [...this.state.answer_option_ids];
            answers[2] = answer_option_ids;
            let score = [...this.state.scores];
            score[2] = scores;
            this.setState({answer_option_ids:answers,scores:score});
        }
        else {
            this.state.answer_option_ids.push(answer_option_ids);
            this.state.scores.push(scores);
        }
        this.setState({
            debtScore:totalScore,
            activeTab:4,
            maxSectionCompleted:currentMax>3?currentMax:3
        });

    };

    handleSection4Submit = (answer_option_ids,totalScore,scores)=>{
        const currentMax = this.state.maxSectionCompleted;
        if(this.state.answer_option_ids && this.state.answer_option_ids.length > 3){
            let answers = [...this.state.answer_option_ids];
            answers[3] = answer_option_ids;
            let score = [...this.state.scores];
            score[3] = scores;
            this.setState({answer_option_ids:answers,scores:score});
        }
        else {
            this.state.answer_option_ids.push(answer_option_ids);
            this.state.scores.push(scores);
        }
        this.setState({
            protectionScore:totalScore,
            activeTab:5,
            maxSectionCompleted:currentMax>4?currentMax:4
        });

    };

    handleSection5Submit = (answer_option_ids,totalScore,scores,answers)=>{
        const currentMax = this.state.maxSectionCompleted;
        if(this.state.answer_option_ids && this.state.answer_option_ids.length > 4){
            let answers = [...this.state.answer_option_ids];
            answers[4] = answer_option_ids;
            let score = [...this.state.scores];
            score[4] = scores;
            this.setState({answer_option_ids:answers,scores:score});
        }
        else {
            this.state.answer_option_ids.push(answer_option_ids);
            this.state.scores.push(scores);
        }
        this.setState({
            section5Answers:answers,
            savingsAndInvestmentsScore:totalScore,
            activeTab:6,
            maxSectionCompleted:currentMax>5?currentMax:5
        });

    };

    handleSection6Submit = (answer_option_ids,totalScore,scores,additional)=>{
        const currentMax = this.state.maxSectionCompleted;
        if(this.state.answer_option_ids && this.state.answer_option_ids.length > 5){
            let answers = [...this.state.answer_option_ids];
            answers[5] = answer_option_ids;
            let score = [...this.state.scores];
            score[5] = scores;
            this.setState({answer_option_ids:answers,scores:score});
        }
        else {
            this.state.answer_option_ids.push(answer_option_ids);
            this.state.scores.push(scores);
        }
        this.setState({
            propertiesAndMortgagesScore:totalScore,
            activeTab:7,
            section6Additional:additional,
            maxSectionCompleted:currentMax>6?currentMax:6
        });

    };

    handleSection7Submit = (answer_option_ids,totalScore,scores)=>{
        const currentMax = this.state.maxSectionCompleted;
        if(this.state.answer_option_ids && this.state.answer_option_ids.length > 6){
            let answers = [...this.state.answer_option_ids];
            answers[6] = answer_option_ids;
            let score = [...this.state.scores];
            score[6] = scores;
            this.setState({answer_option_ids:answers,scores:score});
        }
        else {
            this.state.answer_option_ids.push(answer_option_ids);
            this.state.scores.push(scores);
        }
        this.setState({
            retirementScore:totalScore,
            activeTab:8,
            maxSectionCompleted:currentMax>7?currentMax:7
        });

    };


    handleSection8Submit = async (answer_option_ids,totalScore,scores)=>{
        const currentMax = this.state.maxSectionCompleted;
        if(this.state.answer_option_ids && this.state.answer_option_ids.length > 7){
            let answers = [...this.state.answer_option_ids];
            answers[7] = answer_option_ids;
            let score = [...this.state.scores];
            score[7] = scores;
            this.setState({answer_option_ids:answers,scores:score});
        }
        else {
            this.state.answer_option_ids.push(answer_option_ids);
            this.state.scores.push(scores);
        }
        this.setState({
            taxScore:totalScore,
            activeTab:9,
            maxSectionCompleted:currentMax>8?currentMax:8
        });
        this.submitSurvey().then(()=>{
            this.setState({showFinalScore:true});
        });

    };

    submitSurvey = async()=>{
        this.setState({loader:true});
        let survey_id = localStorage.getItem('survey_id');
        let resultData = {};
        resultData.survey_id = survey_id;
        resultData.answers = this.state.answer_option_ids?[].concat.apply([], this.state.answer_option_ids):[];
        resultData.section_scores = [];
        resultData.section_scores.push({section_id:1,score:this.state.moneyWorriesScore});
        resultData.section_scores.push({section_id:2,score:this.state.budgetingAndPlanningScore});
        resultData.section_scores.push({section_id:3,score:this.state.debtScore});
        resultData.section_scores.push({section_id:4,score:this.state.protectionScore});
        resultData.section_scores.push({section_id:5,score:this.state.savingsAndInvestmentsScore});
        resultData.section_scores.push({section_id:6,score:this.state.propertiesAndMortgagesScore});
        resultData.section_scores.push({section_id:7,score:this.state.retirementScore});
        resultData.section_scores.push({section_id:8,score:this.state.taxScore});
        const scoreResponse =  await axios.post(`${routes.SAVE_ANSWERS}`,resultData);
        this.setState({loader:false});
        if(scoreResponse.status === 200){
            this.setState({
                finalScoreResponse:scoreResponse.data,
            });
        }
    };

    handleTabChange = (e,{activeIndex}) => {
        if(this.state.maxSectionCompleted >= activeIndex){
            this.setState({activeTab:activeIndex+1});
        }
    };


    render(){
        let {activeTab,maxSectionCompleted,scores} = this.state;
        let organisation = JSON.parse(localStorage.getItem("organisation"));
        let orgLogo = (organisation&&organisation.logo&&organisation.logo.length>2)?organisation.logo:null;
        let answers = this.state['answer_option_ids'];
        maxSectionCompleted = maxSectionCompleted + 1;
        const panes = [
            { menuItem: <MenuItem className={(activeTab < 1 && !(maxSectionCompleted > 0)) ? "visited-section":"section-1" } key={1}>
                    <Popup
                    trigger={<p>1</p>}
                    content={<p onClick={()=>{this.setState({open:false})}}>You can click here to navigate back</p>}
                    position='right center'
                    on='hover'
                    open={this.state.open}
                />
                </MenuItem>, render: () => <Tab.Pane className="section-1-content"><Section1 handleSubmit={this.handleSection1Submit} sectionAnswers={answers[0]} sectionScores={scores[0]} orgLogo={orgLogo}/></Tab.Pane> },
            { menuItem: <MenuItem className={(activeTab < 2 && !(maxSectionCompleted > 1)) ? "visited-section":"section-2" } key={2}>2</MenuItem>, render: () => <Tab.Pane className="section-2-content"><Section2 handleSubmit={this.handleSection2Submit} sectionAnswers={answers[1]} sectionScores={scores[1]} orgLogo={orgLogo}/></Tab.Pane> },
            { menuItem: <MenuItem className={(activeTab < 3 && !(maxSectionCompleted > 2)) ? "visited-section":"section-3" } key={3}>3</MenuItem>, render: () => <Tab.Pane className="section-3-content"><Section3 handleSubmit={this.handleSection3Submit} sectionAnswers={answers[2]} sectionScores={scores[2]} orgLogo={orgLogo}/></Tab.Pane> },
            { menuItem: <MenuItem className={(activeTab < 4 && !(maxSectionCompleted > 3)) ? "visited-section":"section-4" } key={4}>4</MenuItem>, render: () => <Tab.Pane className="section-4-content"><Section4 handleSubmit={this.handleSection4Submit} sectionAnswers={answers[3]} sectionScores={scores[3]} orgLogo={orgLogo}/></Tab.Pane> },
            { menuItem: <MenuItem className={(activeTab < 5 && !(maxSectionCompleted > 4)) ? "visited-section":"section-5" } key={5}>5</MenuItem>, render: () => <Tab.Pane className="section-5-content"><Section5 handleSubmit={this.handleSection5Submit} sectionAnswers={answers[4]} sectionScores={scores[4]} answers={this.state.section5Answers} orgLogo={orgLogo}/></Tab.Pane> },
            { menuItem: <MenuItem className={(activeTab < 6 && !(maxSectionCompleted > 5)) ? "visited-section":"section-6" } key={6}>6</MenuItem>, render: () => <Tab.Pane className="section-6-content"><Section6 handleSubmit={this.handleSection6Submit} sectionAnswers={answers[5]} sectionScores={scores[5]} additional={this.state.section6Additional} orgLogo={orgLogo}/></Tab.Pane> },
            { menuItem: <MenuItem className={(activeTab < 7 && !(maxSectionCompleted > 6)) ? "visited-section":"section-7" } key={7}>7</MenuItem>, render: () => <Tab.Pane className="section-7-content"><Section7 handleSubmit={this.handleSection7Submit} sectionAnswers={answers[6]} sectionScores={scores[6]} orgLogo={orgLogo}/></Tab.Pane> },
            { menuItem: <MenuItem className={(activeTab < 8 && !(maxSectionCompleted > 7)) ? "visited-section":"section-8" } key={8}>8</MenuItem>, render: () => <Tab.Pane className="section-8-content"><Section8 handleSubmit={this.handleSection8Submit} sectionAnswers={answers[7]} sectionScores={scores[7]} orgLogo={orgLogo}/></Tab.Pane> },
        ];
        return(
            !this.state.showFinalScore ?<div>
                <Dimmer active={this.state.loader}>
                    <Loader size='large'>Loading</Loader>
                </Dimmer>
                <Tab activeIndex={this.state.activeTab -1 } className="survey-list" grid = {{ paneWidth: 15, tabWidth: 1 }}	menu={{ fluid: true, vertical: true, tabular: true }} panes={panes} onTabChange={this.handleTabChange}/>
            </div>:this.state.showFinalScore ? <ScoreChart 
            scores={this.state.finalScoreResponse}
            // scores={dummy_data}
             />:null
        )
    }
}
export default SurveyList;