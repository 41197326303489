export const scrollSmoothToTop = (scrollOffset,elRef) => {
    let scrollStep = scrollOffset / (1000 / 15),
        scrollInterval = setInterval(function(){
            if ( scrollOffset >= scrollStep) {
                scrollOffset = scrollOffset - scrollStep;
                elRef.parentNode.scrollBy(0,scrollStep);
            }
            else clearInterval(scrollInterval);
        },15);
};

export const scrollSmoothToBottom = (scrollOffset,elRef) => {
    let scrollStep = scrollOffset / (1000 / 15),
        scrollInterval = setInterval(function(){
            if ( scrollOffset >= scrollStep) {
                scrollOffset = scrollOffset - scrollStep;
                elRef.parentNode.scrollBy(0,-scrollStep);
            }
            else clearInterval(scrollInterval);
        },15);
};