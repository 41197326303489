import React, {Component} from 'react';
import {Grid, GridColumn, Image, MenuItem, Tab, Header, Loader, Modal, Popup, GridRow, Button} from "semantic-ui-react";
import * as logo from "../../../assets/logo_white.png";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import HowAppWorks from "../general/howAppWorks";
import { jsPDF } from "jspdf";
// import 'jspdf-autotable';
import domtoimage from 'dom-to-image';
import { LOGO_BASE64 } from './LOGO_BASE64';
import Card from '../general/card';
import axios from 'axios';

import "../../../assets/fonts/Arimo-Regular-normal"
import { PDF_DOWNLOAD_COUNT } from '../constants/api_routes';

class ScoreChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 10,
            scoreTaken: 0,
            globalScore: 0,
            showLowScoreAlert: false,
            hovered: 0,
            loading: true,
            testSummaryText: ``,
        };
        this._mounted = false;
    }

    componentWillMount() {
        this.organisation = JSON.parse(localStorage.getItem("organisation"));
    }

    componentDidMount() {
        this._mounted = true;
        setTimeout(() => {
            // this.buildScore(this.props.scores['participant_overall_score'], true);
            // this.buildScore(this.props.scores['avg_overall_score'], false);
            // localStorage.clear();
        }, 3000);
        setTimeout(() => {
            this._mounted && this.setState({showLowScoreAlert: true});
        }, 7000);

        setTimeout(() => {
            this.setState({loading: false});
        }, 4500)
    }

    buildScore = (score, actual) => {
        let counterStep = score / (1000 / 15),
            buildScore = setInterval(() => {
                if ((actual && (this.state.scoreTaken <= score)) || (!actual && this.state.globalScore <= score)) {
                    if (actual) {
                        this._mounted && this.setState({scoreTaken: Math.round(this.state.scoreTaken + counterStep)});
                    } else {
                        this._mounted && this.setState({globalScore: Math.round(this.state.globalScore + counterStep)});
                    }
                } else {
                    if (actual) {
                        this._mounted && this.setState({scoreTaken: score});
                    } else {
                        this._mounted && this.setState({globalScore: score});
                    }
                    clearInterval(buildScore);
                }
            }, 15);
    };

    genrateSummary = () => {
        const scores = this.props.scores['section_score'];
        let lowFlagCount = 0;
        const avgScore = [55.6, 48.8, 67.7, 42.5, 54.1, 50.3, 61.2, 48.5];
        const sectionNames = ["money worries", "budgeting & planning", "debt", "protection", "savings & investments", "property & mortgages", "retirement", "tax"];
        let lowScoreSections = [];
        for (let i in avgScore) {
            if (scores[i]['participant_section_score'] < avgScore[i]) {
                lowFlagCount = lowFlagCount + 1;
                lowScoreSections.push(sectionNames[i]);
            }
        }

        if (lowFlagCount === 0) {
            this.setState({testSummaryText: `Well done! You've scored really well in every section and are above the UK average.`})
           
        }
        if (lowFlagCount >= 7) {
            let stringCat = lowScoreSections.splice(0, lowScoreSections.length - 1).join(", ") + " and " + lowScoreSections[lowScoreSections.length - 1];
            this.setState({testSummaryText: `You may need to get more guidance around ${stringCat} as you’re below the UK average`})
           
        }
        if (lowFlagCount === 5 || lowFlagCount === 6) {
            let stringCat = lowScoreSections.splice(0, lowScoreSections.length - 1).join(", ") + " and " + lowScoreSections[lowScoreSections.length - 1];
            this.setState({testSummaryText: `You've scored really well in some sections but may need to get a bit more guidance around ${stringCat} as you're below the UK average.`})
            
        }
        if (lowFlagCount > 0 && lowFlagCount < 5) {
            let stringCat = "";
            if(lowFlagCount === 1){
                stringCat = lowScoreSections[0];
            }
            else {
                stringCat = lowScoreSections.splice(0, lowScoreSections.length - 1).join(", ") + " and " + lowScoreSections[lowScoreSections.length - 1];
            }

            this.setState({testSummaryText: `You've scored really well in most sections but may need to get a bit more guidance around ${stringCat} as you're below the UK average.`})          
        }
    }

    pdfDownloadCount = async () => {
        const survey_id = localStorage.getItem('survey_id');
        const formData = new FormData();
        formData.append("survey_id", survey_id);
        try {
            await axios.put(`${PDF_DOWNLOAD_COUNT}`,formData);
            localStorage.clear();
        } catch (error) {
            console.log("Something went wrong", error)
        }
    }

    downloadResultPdfHandler = async () => {
        this.genrateSummary()

        const isScreenWidth1280 = window.matchMedia("(min-width: 1280px)").matches;
        const isScreenWidth1350 = window.matchMedia("(min-width: 1250px)").matches;
        const isScreenWidth1450 = window.matchMedia("(min-width: 1450px)").matches;
        const isScreenWidth1600Plus = window.matchMedia("(min-width: 1600px)").matches;
        const isScreenWidth1700Plus = window.matchMedia("(min-width: 1700px)").matches;
        const isScreenWidth1800Plus = window.matchMedia("(min-width: 1800px)").matches;
        const isScreenWidth1900Plus = window.matchMedia("(min-width: 1900px)").matches;

        let chartImgWidth = 120;

        if(isScreenWidth1280) chartImgWidth = 100;
        if(isScreenWidth1350) chartImgWidth = 110;
        if(isScreenWidth1450) chartImgWidth = 120;
        if(isScreenWidth1600Plus) chartImgWidth = 130;
        if(isScreenWidth1700Plus) chartImgWidth = 140;
        if(isScreenWidth1800Plus) chartImgWidth = 150;
        if(isScreenWidth1900Plus) chartImgWidth = 158;

        this.setState({loading: true})

        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        const month = months[new Date().getMonth()];
        const date = new Date().getDate();
        const year = new Date().getFullYear();

        const currDate = `${date} ${month} ${year}`; // 8 December 2021

    try {
        const chart1 = document.querySelector('#chart1');
        const scoreCards = document.querySelector('#score-cards')

        const pdf = new jsPDF();
        
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
        
        let chart1Img = await  domtoimage.toJpeg(chart1, {
            bgcolor: '#002060'
        });
        let scoreCardsImg = await domtoimage.toJpeg(scoreCards, {bgcolor: "#002060", quality: 1});



        pdf.setFont('Helvetica', 'normal')
        pdf.setFontSize(18)
        pdf.setTextColor('#fff')
        pdf.setFillColor(0,32,96);
        pdf.setDrawColor(255, 255, 255);
        pdf.rect(0, 0, width, height, "F");
        pdf.addImage(LOGO_BASE64, 'JPEG', 5, 6, 50, 10);
        pdf.addImage(chart1Img, "JPEG", 87, 25, chartImgWidth, 100);
        pdf.addImage(scoreCardsImg, "JPEG",12, 145, width - 20, 50);
        pdf.rect(5,5,width - 10, height - 10);
        pdf.setTextColor('#ffffff');
        pdf.text('Your overall score', 115, 25);
        pdf.text('Your scores in each area', 12, 140);
        pdf.text(
'Your financial fitness test results', 10 , 42, {maxWidth: 55})
pdf.setFontSize(12);
pdf.text(currDate, 10, 65);
pdf.setFont('Helvetica', 'normal');
pdf.text(this.state.testSummaryText, 10, 73,{maxWidth: 65});


        pdf.setFontSize(18)
        pdf.setFontSize(12);

        pdf.text('No matter what your score, the first step to improving your financial fitness is taking this test, and seeing the specific areas where you\'re likely to benefit from more guidance. Whether your score is above or below the UK average, help is at hand on all areas of personal finance', 8, 210, {maxWidth: width - 16});

        pdf.text(
'For further information on improving your financial wellbeing, why not visit your Money Talks hub which contains lots of useful information on topics such as budgeting, retirement planning, bite-size videos, case studies and much more.', 8, 230, {maxWidth: width - 16});
       
        pdf.save(`${new Date().toISOString()}-wellbeing-score-close-brothers.pdf`);
       this.organisation && this.pdfDownloadCount();
        } catch (error) {
            console.log('table error',error);
        } finally {
            this.setState({loading: false});
        }
       
    }

    computeResultInformation = () => {
        // localStorage.clear();
        const scores = this.props.scores['section_score'];
        const url =  this.props.scores["organisation_url"] ?  this.props.scores["organisation_url"] : "https://www.closebrothersam.com/for-employers/financial-wellbeing/";
        const contactNumber =  this.props.scores["organisation_contact"] ?  this.props.scores["organisation_contact"] : "0800 028 0208";
        
        let lowFlagCount = 0;
        const avgScore = [55.6, 48.8, 67.7, 42.5, 54.1, 50.3, 61.2, 48.5];
        let sectionNames = [];
        if(this.organisation || this.props.scores.section_urls){
            // let section_urls = this.organisation.section_urls;
            let section_urls = this.props.scores.section_urls || this.organisation.section_urls;
            for(var i in section_urls){
                sectionNames.push(<a key={i} href={section_urls[i].url} target="_blank"  rel="noopener noreferrer" >{section_urls[i].section_name.toLowerCase()}</a>)
            }
        }
        else{
            sectionNames = ["money worries", "budgeting & planning", "debt", "protection", "savings & investments", "property & mortgages", "retirement", "tax"];
        }
        let lowScoreSections = [];
        for (let i in avgScore) {
            if (scores[i]['participant_section_score'] < avgScore[i]) {
                lowFlagCount = lowFlagCount + 1;
                lowScoreSections.push(sectionNames[i]);
            }
        }
        if (lowFlagCount === 0) {
            return (<div>
                <p>Well done! You've scored really well in every section and are above the UK average.  <Popup trigger={<span>
                        <Icon className="icon-button" name="info circle" size="small"  />
                      </span>} content={<div className="score-final-information">
                        <p>
                          The UK average is calculated using data
                          from people like you which is collated
                          from each financial wellbeing section,
                          based on your age, gender and salary.
                        </p>
                      </div>} wide="very" /></p>
                      <p>No matter what your score, the first step to improving your financial fitness is taking this test, and seeing the specific areas where you&apos;re likely to benefit from more guidance. Whether your score is above or below the UK average, help is at hand on all areas of personal finance.  Check out your  <a href={url} target="_blank"  rel="noopener noreferrer" className='low-score-section-names' >Money Talks hub</a> or contact Close Brothers to find out how we can help. </p><p></p>
                <div className="phone-number"><span>Call us: </span><span className="phone-number"><a
                    href="tel:0800 028 0208">{contactNumber}</a></span></div>
                <div>
               <p></p>
                <p></p>
                <p>You can now <span onClick={this.downloadResultPdfHandler} className="phone-number" style={{textDecoration: 'underline', fontWeight: 'normal', cursor: 'pointer'}}>download your latest results</span> and save them for future reference.</p>
                    <p style={{fontSize:10}}>
                    Telephone calls made to any member of Close Brothers Asset Management may be recorded. For 
                    more information about how we process your data, please see 
                    <a href="https://www.closebrothersam.com/legal-centre/privacy-policy/." target="_blank"  rel="noopener noreferrer" >
                        www.closebrothersam.com/legal-centre/privacy-policy/
                    </a>.
                    </p>

                    <Button style={{float: 'right', marginBottom:20}} icon disabled={this.state.loading} secondary onClick={this.downloadResultPdfHandler}> <Icon name="download" /> &nbsp; {this.state.loading ? "Loading..." :"Download your results"}</Button>
                </div>
            </div>);
        }
        if (lowFlagCount >= 7) {
            let stringCat;
            if(this.organisation || this.props.scores.section_urls){
                let sections = lowScoreSections.splice(0,lowScoreSections.length -1);
                stringCat = <React.Fragment>
                    {sections.map((sec,i)=>{
                        return (<React.Fragment key={i}>
                                    <span>{sec}</span>
                                {(i<sections.length-1)&&<span>, </span>}
                                </React.Fragment>);
                    })}
                </React.Fragment>
                stringCat = <React.Fragment>{stringCat}<span> <span style={{color:'black', fontWeight: 'normal', fontFamily: "'HelveticaRg'"}}>and</span> {lowScoreSections[lowScoreSections.length - 1]}</span></React.Fragment>

            }
            else {
                stringCat = <React.Fragment>{lowScoreSections.splice(0, lowScoreSections.length - 1).join(", ")} <span style={{color:'black', fontWeight: 'normal', fontFamily: "'HelveticaRg'"}}>and</span> {lowScoreSections[lowScoreSections.length - 1]}</React.Fragment>
            }
            return (<div>
                <p>
                    <span>You may need to get more guidance around</span>
                    <span className="low-score-section-names normal"> {stringCat} </span> <span> as you&apos;re below the UK average.<Popup trigger={<span>
                        <Icon className="icon-button" name="info circle" size="small"  />
                      </span>} content={<div className="score-final-information">
                        <p>
                          The UK average is calculated using data
                          from people like you which is collated
                          from each financial wellbeing section,
                          based on your age, gender and salary.
                        </p>
                      </div>} wide="very" /></span>
                </p>
                <p> No matter what your score, the first step to improving your financial fitness is taking this test, and seeing the specific areas where you&apos;re likely to benefit from more guidance. Whether your score is above or below the UK average, help is at hand on all areas of personal finance.  Check out your  <a href={url} target="_blank"  rel="noopener noreferrer" className='low-score-section-names' >Money Talks hub</a> or contact Close Brothers to find out how we can help.</p>
                <p></p>
                <p>You can now <span onClick={this.downloadResultPdfHandler} className="phone-number" style={{textDecoration: 'underline', fontWeight: 'normal', cursor: 'pointer'}}>download your latest results</span> and save them for future reference.</p>
                <div className="phone-number"><span>Call us: </span><span className="phone-number"><a
                    href="tel:0800 028 0208">{contactNumber}</a></span></div>
                    <div>
                       <p></p>
                <p></p>
                    <p style={{fontSize:10}}>
                    Telephone calls made to any member of Close Brothers Asset Management may be recorded. For 
                    more information about how we process your data, please see <a href="https://www.closebrothersam.com/legal-centre/privacy-policy/." target="_blank"  rel="noopener noreferrer" >
                        www.closebrothersam.com/legal-centre/privacy-policy/
                    </a>.
                    </p>

                    <Button style={{float: 'right', marginBottom:20}} icon disabled={this.state.loading} secondary onClick={this.downloadResultPdfHandler}> <Icon name="download" /> &nbsp; {this.state.loading ? "Loading..." :"Download your results"}</Button>
                </div>
            </div>);
        }
        if (lowFlagCount === 5 || lowFlagCount === 6) {
            let stringCat;
            if(this.organisation || this.props.scores.section_urls){
                let sections = lowScoreSections.splice(0,lowScoreSections.length -1);
                stringCat = <React.Fragment>
                    {sections.map((sec,i)=>{
                        return (<React.Fragment key={i}>
                                    <span>{sec}</span>
                                {(i<sections.length-1)&&<span>, </span>}
                                </React.Fragment>);
                    })}
                </React.Fragment>
                stringCat = <React.Fragment>{stringCat}<span> <span style={{color:'black', fontWeight: 'normal', fontFamily: "'HelveticaRg'"}}>and</span> {lowScoreSections[lowScoreSections.length - 1]}</span></React.Fragment>

            }
            else {
                stringCat = <React.Fragment>{lowScoreSections.splice(0, lowScoreSections.length - 1).join(", ")} <span style={{color:'black', fontWeight: 'normal', fontFamily: "'HelveticaRg'"}}>and</span> {lowScoreSections[lowScoreSections.length - 1]}</React.Fragment>
            }
            return (<div>
                <p>
                    <span>You've scored really well in some sections but may need to get a bit more guidance around </span>
                    <span className={this.organisation?"low-score-section-names":"low-score-section-names"}>{stringCat}</span>
                    <span> as you're below the UK average.  <Popup trigger={<span>
                        <Icon className="icon-button" name="info circle" size="small"  />
                      </span>} content={<div className="score-final-information">
                        <p>
                          The UK average is calculated using data
                          from people like you which is collated
                          from each financial wellbeing section,
                          based on your age, gender and salary.
                        </p>
                      </div>} wide="very" /></span>
                </p>
                <p> No matter what your score, the first step to improving your financial fitness is taking this test, and seeing the specific areas where you&apos;re likely to benefit from more guidance. Whether your score is above or below the UK average, help is at hand on all areas of personal finance.  Check out your  <a href={url} target="_blank"  rel="noopener noreferrer" className='low-score-section-names' >Money Talks hub</a> or contact Close Brothers to find out how we can help.</p>
                <p></p>
                <div className="phone-number"><span>Call us: </span><span className="phone-number"><a
                    href="tel:0800 028 0208">{contactNumber}</a></span></div>
                    <div>
                       <p></p>
                <p></p>
                <p>You can now <span onClick={this.downloadResultPdfHandler} className="phone-number" style={{textDecoration: 'underline', fontWeight: 'normal', cursor: 'pointer'}}>download your latest results</span> and save them for future reference.</p>
                    
                    <p style={{fontSize:10}}>
                    Telephone calls made to any member of Close Brothers Asset Management may be recorded. For 
                    more information about how we process your data, please see <a href="https://www.closebrothersam.com/legal-centre/privacy-policy/." target="_blank"  rel="noopener noreferrer" >
                        www.closebrothersam.com/legal-centre/privacy-policy/
                    </a>.
                    </p>

                    <Button style={{float: 'right', marginBottom:20}} icon disabled={this.state.loading} secondary onClick={this.downloadResultPdfHandler}> <Icon name="download" /> &nbsp; {this.state.loading ? "Loading..." :"Download your results"}</Button>
                </div>
            </div>);
        }
        if (lowFlagCount > 0 && lowFlagCount < 5) {
            let stringCat = "";
            if(lowFlagCount === 1){
                stringCat = lowScoreSections[0];
            }
            else if(this.organisation || this.props.scores.section_urls){
                if(this.organisation || this.props.scores.section_urls){
                    let sections = lowScoreSections.splice(0,lowScoreSections.length -1);
                    stringCat = <React.Fragment>
                        {sections.map((sec,i)=>{
                            return (<React.Fragment key={i}>
                                        <span>{sec}</span>
                                        {(i<sections.length-1)&&<span>, </span>}
                                    </React.Fragment>)
                        })}
                    </React.Fragment>
                    stringCat = <React.Fragment>{stringCat}<span> <span style={{color:'black', fontWeight: 'normal', fontFamily: "'HelveticaRg'"}}>and</span> {lowScoreSections[lowScoreSections.length - 1]}</span></React.Fragment>
                }
            }
            else {
                stringCat = <React.Fragment>{lowScoreSections.splice(0, lowScoreSections.length - 1).join(", ")} <span style={{color:'black', fontWeight: 'normal', fontFamily: "'HelveticaRg'"}}>and</span> {lowScoreSections[lowScoreSections.length - 1]}</React.Fragment>
            }
            return <div>
                <p>
                  <span>
                    You've scored really well in most sections but
                    may need to get a bit more guidance around{" "}
                  </span>
                  <span className="low-score-section-names">
                    {stringCat}
                  </span>
                  <span> as you're below the UK average. </span>
                  <Popup trigger={<span>
                        <Icon className="icon-button" name="info circle" size="small"  />
                      </span>} content={<div className="score-final-information">
                        <p>
                          The UK average is calculated using data
                          from people like you which is collated
                          from each financial wellbeing section,
                          based on your age, gender and salary.
                        </p>
                      </div>} wide="very" />
                </p>
                <p />
                <p>
                No matter what your score, the first step to improving your financial fitness is taking this test, and seeing the specific areas where you&apos;re likely to benefit from more guidance. Whether your score is above or below the UK average, help is at hand on all areas of personal finance.  Check out your  <a href={url} target="_blank"  rel="noopener noreferrer" className='low-score-section-names' >Money Talks hub</a> or contact Close Brothers to find out how we can help.
                </p>
                <div className="phone-number">
                  <span> Call us: </span>
                  <span>
                    <a href="tel:0800 028 0208">{contactNumber}</a>
                  </span>
                </div>
               <p></p>
                <p>
                  You can now <span onClick={this.downloadResultPdfHandler} className="phone-number" style={{ textDecoration: "underline", fontWeight: "normal", cursor: "pointer" }}>
                    download your latest results
                  </span> and save them for future reference.
                </p>
                <p />
                <div>

                <p style={{fontSize:10}}>
                    Telephone calls made to any member of Close Brothers Asset Management may be recorded. For 
                    more information about how we process your data, please see <a href="https://www.closebrothersam.com/legal-centre/privacy-policy/." target="_blank"  rel="noopener noreferrer" >
                        www.closebrothersam.com/legal-centre/privacy-policy/
                    </a>.
                </p>

                  <Button style={{ float: "right", marginBottom: 20 }} icon disabled={this.state.loading} secondary onClick={this.downloadResultPdfHandler}>
                    {" "}
                    <Icon name="download" /> &nbsp; {this.state.loading ? "Loading..." : "Download your results"}
                  </Button>
                </div>
              </div>;
        }
        return null;
    };

    componentWillUnmount() {
        this._mounted = false;
    }

    render() {
        const scores = this.props.scores;
        const sectionScore = scores['section_score'];
        const participant_overall_score = scores['participant_overall_score'];
        const avg_overall_score = scores['avg_overall_score']

        const panes = [
            { menuItem: <MenuItem className={"section-1"} key={1}>1</MenuItem>,render: () => <Tab.Pane className="section-1-content"></Tab.Pane>},
            { menuItem: <MenuItem className={"section-2"} key={2}>2</MenuItem>,render: () => <Tab.Pane className="section-1-content"></Tab.Pane>},
            { menuItem: <MenuItem className={"section-3"} key={3}>3</MenuItem>, render: () => <Tab.Pane className="section-1-content"></Tab.Pane>},
            {menuItem: <MenuItem className={"section-4"} key={4}>4</MenuItem>, render: () => <Tab.Pane className="section-1-content"></Tab.Pane>},
            {menuItem: <MenuItem className={"section-5"} key={5}>5</MenuItem>,render: () => <Tab.Pane className="section-1-content"></Tab.Pane>},
            {menuItem: <MenuItem className={"section-6"} key={6}>6</MenuItem>,render: () => <Tab.Pane className="section-1-content"></Tab.Pane>},
            {menuItem: <MenuItem className={"section-7"} key={7}>7</MenuItem>,render: () => <Tab.Pane className="section-1-content"></Tab.Pane>},
            {menuItem: <MenuItem className={"section-8"} key={8}>8</MenuItem>,render: () => <Tab.Pane className="section-1-content"></Tab.Pane>}
        ];
        
        const borderColors = ["#254090", "#779B4C", "#1A8496", "#692175", "#D21769", "#01768D", "#75BC24", "#FF8301"]
        
        return (
            <div className="survey-list">
                <Grid className="section-main-div final-score-section">
                    <GridColumn width={3} className="section-details-sp">
                        <Image id="logo" src={logo}/>
                    </GridColumn>
                    <GridColumn width={13} className="lens-div max-screen-lens">
                        <div className="lens">
                            <Image src="/web_lens_full.png"/>
                        </div>
                        {(scores && sectionScore) ? <div className="score-cards-container">
                            <GridRow style={{ position: "absolute", top: "20px", right: "25%"}}>
                            <Button icon disabled={this.state.loading} secondary onClick={this.downloadResultPdfHandler}> <Icon name="download" /> &nbsp; {this.state.loading ? "Loading..." :"Download your results"}</Button> 
                            </GridRow>

                            <div id="result">
                            <div  style={{width: '50%', float: 'left', paddingLeft: '10px'}}>
                                <Header className="score-header" textAlign="center" style={{paddingRight:'10px', marginBottom: "0px", marginTop: 10}}>
                                   <span className='your-score-title' style={{fontSize: '22px'}}>Your overall score</span>  <Popup on="hover" hoverable
                                    trigger={ <span><Icon className="icon-button" name="info circle" size="small" /></span>}
                                    content={<div className="score-final-information">
                                        {this.props.scores && this.computeResultInformation()}
                                    </div>}
                                    wide="very"
                                />
                                </Header>
                                <div className="sc-wrapper" id="chart1">
                                    <div
                                        className={this.state.hovered === 1 ? "fn-score-card sc-section-1 hoveredCard" : "fn-score-card sc-section-1 card"}
                                        onMouseEnter={() => {
                                            this.setState({hovered: 1})
                                        }} onMouseLeave={() => this.setState({hovered: 0})}>
                                        <div style={{bottom: -10}}>
                                            <span
                                                className="score-value">{Math.round(sectionScore[0]['participant_section_score'])}</span><br/>
                                            <span>Money <br/> worries</span>
                                        </div>
                                    </div>
                                    <div
                                        className={this.state.hovered === 2 ? "fn-score-card sc-section-2 hoveredCard" : "fn-score-card sc-section-2 card"}
                                        onMouseEnter={() => {
                                            this.setState({hovered: 2})
                                        }} onMouseLeave={() => this.setState({hovered: 0})}>
                                        <div>
                                            <span
                                                className="score-value">{Math.round(sectionScore[1]['participant_section_score'])}</span><br/>
                                            <span>Budgeting & planning</span>
                                        </div>
                                    </div>
                                    <div
                                        className={this.state.hovered === 3 ? "fn-score-card sc-section-3 hoveredCard" : "fn-score-card sc-section-3 card"}
                                        onMouseEnter={() => {
                                            this.setState({hovered: 3})
                                        }} onMouseLeave={() => this.setState({hovered: 0})}>
                                        <div>
                                            <span
                                                className="score-value">{Math.round(sectionScore[2]['participant_section_score'])}</span><br/>
                                            <span>Debt</span>
                                        </div>
                                    </div>
                                    <div
                                        className={this.state.hovered === 4 ? "fn-score-card sc-section-4 hoveredCard" : "fn-score-card sc-section-4 card"}
                                        onMouseEnter={() => {
                                            this.setState({hovered: 4})
                                        }} onMouseLeave={() => this.setState({hovered: 0})}>
                                        <div>
                                            <span
                                                className="score-value">{Math.round(sectionScore[3]['participant_section_score'])}</span><br/>
                                            <span>Protection</span>
                                        </div>
                                    </div>
                                    <div
                                        className={this.state.hovered === 5 ? "fn-score-card sc-section-5 hoveredCard" : "fn-score-card sc-section-5 card"}
                                        onMouseEnter={() => {
                                            this.setState({hovered: 5})
                                        }} onMouseLeave={() => this.setState({hovered: 0})}>
                                        <div style={{paddingTop: '20px'}}>
                                            <span
                                                className="score-value">{Math.round(sectionScore[4]['participant_section_score'])}</span><br/>
                                            <span>Savings & investments</span>
                                        </div>
                                    </div>
                                    <div
                                        className={this.state.hovered === 6 ? "fn-score-card sc-section-6 hoveredCard" : "fn-score-card sc-section-6 card"}
                                        onMouseEnter={() => {
                                            this.setState({hovered: 6})
                                        }} onMouseLeave={() => this.setState({hovered: 0})}>
                                        <div style={{marginTop: -12}}>
                                            <span
                                                className="score-value">{Math.round(sectionScore[5]['participant_section_score'])}</span><br/>
                                            <span style={{width: '70%'}}>Property & mortgages</span>
                                        </div>
                                    </div>
                                    <div
                                        className={this.state.hovered === 7 ? "fn-score-card sc-section-7 hoveredCard" : "fn-score-card sc-section-7 card"}
                                        onMouseEnter={() => {
                                            this.setState({hovered: 7})
                                        }} onMouseLeave={() => this.setState({hovered: 0})}>
                                        <div style={{marginLeft: '10px'}}>
                                            <span
                                                className="score-value">{Math.round(sectionScore[6]['participant_section_score'])}</span><br/>
                                            <span>Retirement</span>
                                        </div>
                                    </div>
                                    <div
                                        className={this.state.hovered === 8 ? "fn-score-card sc-section-8 hoveredCard" : "fn-score-card sc-section-8 card"}
                                        onMouseEnter={() => {
                                            this.setState({hovered: 8})
                                        }} onMouseLeave={() => this.setState({hovered: 0})}>
                                        <div>
                                            <span
                                                className="score-value">{Math.round(sectionScore[7]['participant_section_score'])}</span><br/>
                                            <span>Tax</span>
                                        </div>
                                    </div>
                                    <div className="disc">
                                        <div className="center-overall-score-large">
                                            <span>Your score</span>
                                            {/* <div style={{paddingTop: '30px'}}><span
                                                className="obtained">{Math.round(this.state.scoreTaken * 10) / 10}</span><span>/100</span>
                                            </div> */}
                                            <div style={{paddingTop: '30px'}}><span
                                                className="obtained">{parseInt(participant_overall_score)}</span><span>/100</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: '42%', float: 'left', paddingTop: '85px', paddingLeft: '5%'}}>
                                <Header className="score-header people-like-you" textAlign="center" style={{paddingRight:'5%'}}>
                                    <span  className='your-score-title' style={{fontSize: "20px"}}>People like you</span>  <Popup hoverable
                                    trigger={ <span><Icon className="icon-button" name="info circle" size="small" onClick={() => {
                                        this.setState({showLowScoreAlert: true})}}/></span>}
                                    content={<div className="score-final-information">
                                        <p>
                                            See how people similar to you have scored in each financial wellbeing section, based on your <span className="low-score-section-names normal"> age, gender and salary</span>.
                                        </p>
                                    </div>}
                                    wide="very"
                                />
                                </Header>
                                <div className="sc-wrapper" id="chart2">
                                    <div
                                        className={this.state.hovered === 1 ? "score-card-small sc-section-small-1 hoveredCard" : "score-card-small sc-section-small-1 card"}
                                        onMouseEnter={() => {
                                            this.setState({hovered: 1})
                                        }} onMouseLeave={() => this.setState({hovered: 0})}>
                                        <div>
                                            <span
                                                className="score-value small">{Math.round(sectionScore[0]['avg_section_score'])}</span>
                                            <span>Money <br/>worries</span>
                                        </div>
                                    </div>
                                    <div
                                        className={this.state.hovered === 2 ? "score-card-small sc-section-small-2 hoveredCard" : "score-card-small sc-section-small-2 card"}
                                        onMouseEnter={() => {
                                            this.setState({hovered: 2})
                                        }} onMouseLeave={() => this.setState({hovered: 0})}>
                                        <div>
                                            <span
                                                className="score-value small">{Math.round(sectionScore[1]['avg_section_score'])}</span>
                                            <span>Budgeting & planning</span>
                                        </div>
                                    </div>
                                    <div
                                        className={this.state.hovered === 3 ? "score-card-small sc-section-small-3 hoveredCard" : "score-card-small sc-section-small-3 card"}
                                        onMouseEnter={() => {
                                            this.setState({hovered: 3})
                                        }} onMouseLeave={() => this.setState({hovered: 0})}>
                                        <div>
                                            <span
                                                className="score-value small">{Math.round(sectionScore[2]['avg_section_score'])}</span>
                                            <span>Debt</span>
                                        </div>
                                    </div>
                                    <div
                                        className={this.state.hovered === 4 ? "score-card-small sc-section-small-4 hoveredCard" : "score-card-small sc-section-small-4 card"}
                                        onMouseEnter={() => {
                                            this.setState({hovered: 4})
                                        }} onMouseLeave={() => this.setState({hovered: 0})}>
                                        <div>
                                            <span
                                                className="score-value small">{Math.round(sectionScore[3]['avg_section_score'])}</span>
                                            <span>Protection</span>
                                        </div>
                                    </div>
                                    <div
                                        className={this.state.hovered === 5 ? "score-card-small sc-section-small-5 hoveredCard" : "score-card-small sc-section-small-5 card"}
                                        onMouseEnter={() => {
                                            this.setState({hovered: 5})
                                        }} onMouseLeave={() => this.setState({hovered: 0})}>
                                        <div style={{paddingTop: '15px'}}>
                                            <span
                                                className="score-value small">{Math.round(sectionScore[4]['avg_section_score'])}</span>
                                            <span>Savings & investments</span>
                                        </div>
                                    </div>
                                    <div
                                        className={this.state.hovered === 6 ? "score-card-small sc-section-small-6 hoveredCard" : "score-card-small sc-section-small-6 card"}
                                        onMouseEnter={() => {
                                            this.setState({hovered: 6})
                                        }} onMouseLeave={() => this.setState({hovered: 0})}>
                                        <div style={{marginTop: -8}}>
                                            <span
                                                className="score-value small">{Math.round(sectionScore[5]['avg_section_score'])}</span>
                                            <span style={{width: '70%'}}>Property & mortgages</span>
                                        </div>
                                    </div>
                                    <div
                                        className={this.state.hovered === 7 ? "score-card-small sc-section-small-7 hoveredCard" : "score-card-small sc-section-small-7 card"}
                                        onMouseEnter={() => {
                                            this.setState({hovered: 7})
                                        }} onMouseLeave={() => this.setState({hovered: 0})}>
                                        <div style={{marginLeft: '10px'}}>
                                            <span
                                                className="score-value small">{Math.round(sectionScore[6]['avg_section_score'])}</span>
                                            <span>Retirement</span>
                                        </div>
                                    </div>
                                    <div
                                        className={this.state.hovered === 8 ? "score-card-small sc-section-small-8 hoveredCard" : "score-card-small sc-section-small-8 card"}
                                        onMouseEnter={() => {
                                            this.setState({hovered: 8})
                                        }} onMouseLeave={() => this.setState({hovered: 0})}>
                                        <div>
                                            <span
                                                className="score-value small">{Math.round(sectionScore[7]['avg_section_score'])}</span>
                                            <span>Tax</span>
                                        </div>
                                    </div>
                                    <div className="disc-small">
                                        <div className="center-overall-score-small">
                                            <span>People like you</span>
                                            {/* <div style={{paddingTop: '10px'}}><span
                                                className="obtained">{Math.round(this.state.globalScore * 10) / 10}</span><span>/100</span>
                                            </div> */}
                                            <div style={{paddingTop: '10px'}}><span
                                                className="obtained">{parseInt(avg_overall_score)}</span><span>/100</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className='mini-score-cards-container' style={{width: "70%", marginLeft: "60px"}}>
                                <div id="score-cards" style={{width: "100%", marginBottom: "-30px",
                             display: 'flex', flexWrap: 'wrap' }}>
                            {sectionScore.map((item, idx) =>  <Card title={item.section_name} score={Math.round(item.participant_section_score)} borderColor={borderColors[idx]} idx={idx}/>)}
                            </div>  
                            </div>
                          
                        </div> : <Loader/>}
                    </GridColumn>
                </Grid>
                <div className="how-final-score" onClick={() => this.setState({showInformation: true})}><Icon
                    name="cogs"/> How the test works
                </div>
                <Tab activeIndex={this.state.activeTab - 1} className="survey-list" grid={{paneWidth: 15, tabWidth: 1}}
                     menu={{fluid: true, vertical: true, tabular: true}} panes={panes}/>
                {this.state.showInformation && <HowAppWorks organisation = {this.organisation?this.organisation:null}
                    close={() => {
                    this.setState({showInformation: false})
                }}/>}
                {this.state.showLowScoreAlert &&
                <Modal open={this.state.showLowScoreAlert} size="small" closeIcon="close" onClose={() => {
                    this.setState({showLowScoreAlert: false})
                }} style={{position:'absolute',left:0,right:0,margin:'auto'}}>
                    <Modal.Content className="score-final-information">
                        <div>
                            {this.props.scores && this.computeResultInformation()}
                        </div>
                    </Modal.Content>
                </Modal>}
            </div>
        )
    }
}

export default ScoreChart;