import React,{Component} from 'react';
import {Button,Label} from "semantic-ui-react";
class ButtonRangeWithNumbers extends Component{
    render() {

        return (
            <div>
                <div className="button-range">
                    <div className="track"></div>
                    {this.props.options.map((option,index)=>{
                        return(
                            this.props.selectedAnswer === option.value ?
                                <Label key={index}circular className="active" style={{left:option.left,color:"#FFFFFF",backgroundColor:option.color}}>{option.text}</Label>:
                                <Button key={index} circular icon="circle" onClick={(e)=>{
                                    e.preventDefault();
                                    this.props.handleSelect(option.value,option.score);
                                }} style={{left:option.left,color:"#A7A7A7"}}/>
                        )
                    })}
                    {/*{this.props.options.map((option,index)=>{*/}
                        {/*return(*/}
                            {/*this.props.selectedAnswer===option.value ?<span key={index} className="dot-text active-dot" style={{left:`calc( ${option.left} - 5%)`,color:option.color}}>{option.label}</span>*/}
                                {/*:null*/}
                        {/*)*/}
                    {/*})}*/}
                    {this.props.options.map((option,index)=>{
                        return(
                           (index === 0 || index === 5 || index === 10)?<span key={index} className="dot-text" style={{left:`calc(${option.left} - 30px)`,width:'100%'}}>{option.label}</span>
                                :null
                        )
                    })}
                </div>
            </div>
        );
    }
}
export default ButtonRangeWithNumbers;