import React,{Component} from 'react';
import {Button,Dropdown,Grid,GridColumn} from "semantic-ui-react";
class YesOrNoWithDropdown extends Component{
    render() {
        return(
            <div className="yes-or-no">
                    <div>
                        <Dropdown className="yes-or-no-dropdown"
                            pointing="top left"
                            selectOnBlur={false}
                            button
                            options={this.props.yesOptions}
                            text='YES' onChange={this.props.handleSelect}
                        />
                        <Dropdown className="yes-or-no-dropdown"
                                  pointing="top left"
                                  selectOnBlur={false}
                                  button
                                  options={this.props.noOptions}
                                  text='NO' onChange={this.props.handleSelect}
                        />
                    </div>
                {(this.props.showAnswer ? this.props.showAnswer :this.props.selectedAnswer) &&
                <div className="selected-answer">
                    <Grid verticalAlign="middle">
                        <GridColumn width={14}>
                            { this.props.showAnswer? this.props.showAnswer :this.props.selectedAnswer}
                        </GridColumn>
                        <GridColumn width={2}>
                            <Button circular icon="checkmark" style={{color:"#78BE20",backgroundColor:"#FFFFFF"}}/>
                        </GridColumn>
                    </Grid>
                </div>}
            </div>
        )
    }
}
export default YesOrNoWithDropdown;