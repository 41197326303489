import React, {Component} from 'react';
import {Modal,Button} from "semantic-ui-react";

class HowAppWorks extends Component {
    render() {
        return (
            <Modal size="small" open={true} onClose={this.props.close} style={{position:'absolute',left:0,right:0,margin:'auto'}}>
                <Modal.Header size="large">How the app works</Modal.Header>
                <Modal.Content className="how-information-content">
                    <p>This app is designed to illustrate your financial wellbeing score based on the information you
                        provide.</p>
                    <p>All figures are based on a number of important assumptions within each of the steps to
                        financial wellbeing including money worries, budgeting, debt, protection, savings & investments,
                        property &amp; mortgages, retirement and tax. </p>
                    <p>Answers you provide will be used to improve the education we provide, but they will remain
                        anonymous.</p>
                    <p>In order to operate this app, we collect and process the following information relating to each
                        user:</p>
                    <ul>
                        <li>Gender</li>
                        <li>Age</li>
                        <li>Salary range</li>
                        <li>Sector</li>
                    </ul>

                    <p>No personal identifiable information is collected and Close Brothers will not record any identifying
                        personal information about you when you use this app.{this.props.organisation && " Aggregated data may be shared with your employer from time to time,"+
                        "to help determine any gaps in employee financial knowledge."}</p>
                    <p>This tool is not advice and should not be used as the basis for making any financial decisions.
                    Before you make any decisions, we strongly recommend you talk to a financial planner.
                    For more information about these calculators, please refer to the important information and
                        assumptions used.</p>
                    <p>For more information on our privacy and cookie policy, <a target="_blank" rel="noreferrer noopener" href="https://www.closebrothersam.com/footer-pages/legal-centre">please visit our website.</a></p>
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Close" onClick={this.props.close}/>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default HowAppWorks;