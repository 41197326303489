/*Debt section questions*/
export const debtQuestions = [
    {
        id:7,
        question:"Which, if any, of the following statements best describes how much debt is an issue for you?",
        options:[
            {key: 35, text: "Debt is a significant issue for me", value:35, question_id: 7, score: 0},
            {key: 36, text: "Debt is a significant issue for me,but I'm taking steps to bring it under control", value:36, question_id: 7, score: 4},
            {key: 37, text: "Debt is a slight issue for me, but it is manageable", value:37, question_id: 7, score: 8},
            {key: 38, text: "Debt is not an issue for me", value:38, question_id: 7, score: 10},
        ]
    },
    {
        id:8,
        question:"How confident are you in knowing where to get help / advice if you have debt issues?",
        options:[
            {key: 39, text: "Very unconfident", value:39, question_id: 88, score: 0,left:"0%",color:"#DA291C"},
            {key: 40, text: "Somewhat unconfident", value:40, question_id: 8, score: 2.5,left:"25%",color:"#FF8200"},
            {key: 41, text: "Neither confident nor unconfident", value:41, question_id:8, score: 5,left:"50%",color:"#FFCD00"},
            {key: 42, text: "Somewhat confident", value:42, question_id: 8, score: 7.5,left:"75%",color:"#A8DD66"},
            {key: 43, text: "Very confident", value:43, question_id: 8, score: 10,left:"100%",color:"#78BE20"},
        ]
    },
]