import React,{Component} from 'react';
import {Dropdown} from "semantic-ui-react";
class DropdownSelect extends Component{
    render() {
        return (
            <div className="dropdown-select">
                <Dropdown selectOnBlur={false} placeholder={this.props.placeholder || 'Select your option'} noResultsMessage="No matching sector found, you may choose 'Other' as your sector if not found" search={this.props.search} fluid selection options={this.props.options} onChange={this.props.handleSelect} />
            </div>
        );
    }
}
export default DropdownSelect;