import React,{Component} from 'react';
import {List} from "semantic-ui-react";
class SectionList extends Component{
    render() {
        return(
                <List className="section-list">
                    <List.Item className={this.props.activeSection===1?"active":"blur"}>Money worries</List.Item>
                    <List.Item className={this.props.activeSection===2?"active":"blur"}>Budgeting & planning</List.Item>
                    <List.Item className={this.props.activeSection===3?"active":"blur"}>Debt</List.Item>
                    <List.Item className={this.props.activeSection===4?"active":"blur"}>Protection</List.Item>
                    <List.Item className={this.props.activeSection===5?"active":"blur"}>Savings & investments</List.Item>
                    <List.Item className={this.props.activeSection===6?"active":"blur"}>Property & mortgages</List.Item>
                    <List.Item className={this.props.activeSection===7?"active":"blur"}>Retirement</List.Item>
                    <List.Item className={this.props.activeSection===8?"active":"blur"}>Tax</List.Item>
                </List>
        )
    }
}
export default SectionList;