import React,{Component} from 'react';
import * as scoreCard from '../../../assets/score_lens.png';
import {Image} from "semantic-ui-react";
class ScoreCard extends Component{
    constructor(props) {
        super(props);
        this.state = {
            counter: 0
        };
        this.genDots = setInterval(() => {
            this.setState({counter: this.state.counter + 1});
            if (this.state.counter === 5 && this._isMounted) {
                this.setState({counter: 0});
            }
        }, 700);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.genDots);
    }

    render() {
        const loadingDots = [".", "..", "...","....","....."];
        return(
            <div className={"score-card"}>
                <Image className={this.props.showScore?"score-card-image":""} src={scoreCard}/>
                <div className={!this.props.showScore?"score-section-title-normal score-title":"score-section-title-scored score-title"}>
                    <span>{this.props.sectionName}</span><br/>
                    {!this.props.showScore ?<div> <span> score </span><br/><span> {loadingDots[this.state.counter]} </span></div>:<div className="score-title" style={{paddingBottom:'15px'}}><span className="your-score">{Math.round(this.props.score)}</span><span style={{fontSize:'20px',fontWeight:'600'}}>/100</span></div>}
                </div>
            </div>
        )
    }
}
export default ScoreCard;