/*Budgeting and planning section questions*/
export const budgetingAndPlanningQuestions = [
    {
        id:4,
        question:"Do you have a financial plan to help you achieve your future goals?",
        yesOptions:[
            {key: 22, text: "Yes – I updated it in the last 12 months",display_text:"I updated it in the last 12 months", value:22, question_id: 4, score: 10},
            {key: 23, text: "Yes – but I’ve not updated it recently",display_text:"Yes – but I’ve not updated it recently", value:23, question_id: 4, score: 5},
        ],
        noOptions:[
            {key: 24, text: "I have never had a financial plan",display_text:"I have never had a financial plan", value:24, question_id: 4, score: 0},
        ]
    },
    {
        id:5,
        question:"How confident do you feel about making financial decisions?",
        options:[
            {key: 25, text: "Very unconfident", value:25, question_id: 5, score: 0,left:"0%",color:"#DA291C"},
            {key: 26, text: "Somewhat unconfident", value:26, question_id: 5, score: 2.5,left:"25%",color:"#FF8200"},
            {key: 27, text: "Neither confident nor unconfident", value:27, question_id:5, score: 5,left:"50%",color:"#FFCD00"},
            {key: 28, text: "Somewhat confident", value:28, question_id: 5, score: 7.5,left:"75%",color:"#A8DD66"},
            {key: 29, text: "Very confident", value:29, question_id: 5, score: 10,left:"100%",color:"#78BE20"},
        ]
    },
    {
        id:6,
        question:"How confident are you that you will have enough money to pay for long-term care in old age?",
        options:[
            {key: 30, text: "Regularly", value:30, question_id: 6, score: 10,left:"0%",color:"#DA291C"},
            {key: 31, text: "At least annually", value:31, question_id: 6, score: 8,left:"20%",color:"#FF8200"},
            {key: 32, text: "When my circumstances change", value:32, question_id:6, score: 10,left:"40%",color:"#FFCD00"},
            {key: 33, text: "As often as needed to monitor my budget against my plan", value:33, question_id: 6, score: 10,left:"60%",color:"#A8DD66"},
            {key: 34, text: "Rarely", value:34, question_id: 6, score: 0,left:"80%",color:"#78BE20"},
            {key: 35, text: "Never", value:35, question_id: 6, score: 5,left:"100%",color:"#78BE20"},
        ]
    },
   
]
