import React, { Component } from "react";
import "semantic-ui-css/semantic.min.css";
import "./assets/stylesheets/main.scss";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./views/web/router";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 1230 });
  }

  render() {
    const isDesktop = this.state.isDesktop;
    return (
      <div>
        {!isDesktop ? (
          <div className="warning-cover">
            <div
              className="warning-text"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <span>
                Our financial fitness test works on screen displays above
                1280px.
              </span>
              <span>
                If you are having problems accessing the test, please try
                reducing your Zoom percentage in your laptop or pc settings, or
                access via your mobile device.
              </span>
              <span> Thank you.</span>
            </div>
          </div>
        ) : (
          <Router>
            <AppRoutes />
          </Router>
        )}
      </div>
    );
  }
}

export default App;
