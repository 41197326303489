import React, { Component } from "react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { Icon, Popup } from "semantic-ui-react";
class RangeSlider extends Component {
  render() {
    return (
      <div className="range-slider">
        <div className="heading">
          <span className="label" style={{ position: "relative" }}>
            {this.props.label}{" "}
            {this.props.tooltip && (
              <Popup
                trigger={<Icon circular name="info" className="info-popup" />}
                content={this.props.tooltip}
                size="small"
              />
            )}
          </span>

          {this.props.selectedRangeLabel && (
            <span className="value">{this.props.selectedRangeLabel}</span>
          )}
        </div>
        <Slider
          min={this.props.min ? this.props.min : 0}
          max={this.props.max ? this.props.max : 100}
          value={this.props.selectedAnswer}
          orientation="horizontal"
          onChange={this.props.handleSelect}
          tooltip={false}
          step={this.props.step}
          labels={this.props.labels}
        />
      </div>
    );
  }
}
export default RangeSlider;