/*Protection section questions*/
import React from "react";
export const protectionQuestions = [
    {
        id:9,
        question:"Do you have a savings fund that is easily accessible that is set aside to pay for emergencies?",
        yesOptions:[
            {key: 44, text: <span>It would cover <b>less than 1 months’</b> typical spending</span>, value:44, question_id: 9, score: 0},
            {key: 45, text: <span>It would cover <b>around 1 months’</b> typical spending</span>, value:45, question_id: 9, score: 3},
            {key: 46, text: <span>It would cover <b>around 3 months’</b> typical spending</span>, value:46, question_id: 9, score: 6},
            {key: 47, text: <span>It would cover <b>around 6 months’</b> typical spending</span>, value:47, question_id: 9, score: 10},
            {key: 48, text: <span>It would cover <b>around 9 months’</b> typical spending</span>, value:48, question_id: 9, score: 9},
            {key: 49, text: <span>It would cover <b>more than 9 months’</b> typical spending</span>, value:49, question_id: 9, score: 8},

        ],
        noOptions:[
            {key: 50, text: "I do not have a savings fund to  pay for emergencies", value:50, question_id: 9, score: -3},
        ]
    },
    {
        id:10,
        question:"How financially prepared are you to withstand unexpected costs, a significant reduction in your income or periods of financial uncertainty?",
        options:[
            {key: 51, text: "Very unprepared", value:51, question_id: 10, score: 0,left:"0%",color:"#DA291C"},
            {key: 52, text: "Somewhat unprepared", value:52, question_id: 10, score: 2.5,left:"25%",color:"#FF8200"},
            {key: 53, text: "Neither prepared nor unprepared", value:53, question_id:10, score: 5,left:"50%",color:"#FFCD00"},
            {key: 54, text: "Somewhat prepared", value:54, question_id: 10, score: 7.5,left:"75%",color:"#A8DD66"},
            {key: 55, text: "Very well prepared", value:55, question_id: 10, score: 10,left:"100%",color:"#78BE20"},
        ]
    },
]